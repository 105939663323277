import React from "react";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";

import { reportElementClickedToGTM } from "../../api/gtm-analytics";
import { Trans, withTranslation } from "react-i18next";
import AuthFactorRemovalConfirmationModal from "./authFactorRemovalConfirmationModal";
import { FLOW_STATE } from "../../utils/flowStates";
import SetTotpFactorFlow from "../pages/SetTotpFactorFlow";
import Tooltip from "@cx/ui/Tooltip";
import { t } from "i18next";

class TotpAccountSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearingTotpFactor: false
    };
  }

  startTotpFactorFlow = (event) => {
    if (this.props.totpFactor.totp.verified) {
      reportElementClickedToGTM(
        "Remove Authentication Ap",
        "Opened Remove Authentication App Modal",
        "Account Security"
      );
      this.props.userTotpFactorChanging({
        state: FLOW_STATE.SHOW_REMOVE_TOTP_FLOW
      });
    } else {
      reportElementClickedToGTM(
        "Authentication App Configure",
        "Opened Authentication App Modal",
        "Account Security"
      );
      this.props.setTotpAcquireApp();
      this.props.userTotpFactorChanging({
        state: FLOW_STATE.SHOW_SET_TOTP_FLOW,
        page: 1
      });
    }
  };

  cancelSetTotpFlow = (makeRemoveCall = true) => {
    this.props.setTotpFactorCancel();
    makeRemoveCall && this.props.removeTotpFactor(true);
    this.props.userTotpFactorChanging({
      state: FLOW_STATE.HIDE_SET_TOTP_FLOW
    });
  };

  cancelRemoveTotpFlow = () => {
    reportElementClickedToGTM(
      "No, keep this factor",
      "Cancel Remove Authentication App",
      "Account Security"
    );
    this.props.userTotpFactorChanging({
      state: FLOW_STATE.HIDE_REMOVE_TOTP_FLOW
    });
  };

  verifyTotpFactor = (verificationCode) => {
    this.props.submitTotpVerificationCode(verificationCode);
  };

  render() {
    const t = this.props.t;
    const recoveryFactor =
      this.props.totpFactor === undefined
        ? {
            flowStatus: {},
            totp: {
              verified: false,
              errorMessage: ""
            }
          }
        : this.props.totpFactor;
    const showSetTotpFactorFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state === FLOW_STATE.SHOW_SET_TOTP_FLOW;
    const showRemoveTotpFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state === FLOW_STATE.SHOW_REMOVE_TOTP_FLOW;
    const factorType = "TOTP";
    const factorDetails = {
      removeFactorModalCancelLabel:
        "security-tab.totp-remove-modal-cancel-label",
      removeFactorModalRemoveLabel:
        "security-tab.totp-remove-modal-remove-label",
      removeFactorModalRemovingLabel:
        "security-tab.totp-remove-modal-removing-label",
      removeFactorModalText: "security-tab.totp-remove-modal-text",
      removeFactorModalTitle: "security-tab.totp-remove-modal-title"
    };
    return (
      <div className="input-field-wrapper">
        <div className={"totp-field"}>
          <TextInput
            displayLabel={false}
            addOnPosition="append"
            htmlId="totp-input-field"
            label={<Trans i18nKey={"common.authentication-app"} />}
            name="textInputAppendChild"
            className="input-field"
            onChange={() => {}} // This field is read-only
            placeholder=""
            value={
              recoveryFactor.totp.verified
                ? t("common.enrolled")
                : t("common.not-enrolled")
            }
            readOnly={true}
            appendChild={
              <Button
                // if totp is not already configured and is not configurable, button needs to be disabled
                // if totp is already configured, button should not be disabled
                disabled={
                  !this.props.isConfigurable && !recoveryFactor.totp.verified
                }
                buttonStyle="secondary"
                htmlId="totp-input-button"
                onClick={(e) => {
                  this.startTotpFactorFlow();
                  e.currentTarget.blur();
                }}
              >
                {recoveryFactor.totp.verified ? (
                  <Trans i18nKey="common.remove" />
                ) : (
                  <Trans i18nKey="common.configure" />
                )}
              </Button>
            }
          />
        </div>
        {showSetTotpFactorFlow && (
          <SetTotpFactorFlow
            dispatch={this.props.dispatch}
            show={showSetTotpFactorFlow}
            onCancel={this.cancelSetTotpFlow}
            verifyTotpFactor={this.verifyTotpFactor}
            flowStatus={recoveryFactor.flowStatus}
            totpVerificationStatus={this.props.totpVerificationStatus}
            setTotpFactor={this.props.setTotpFactor}
            setTotpAcquireApp={this.startTotpFactorFlow}
            setTotpVerificationStatus={this.props.setTotpVerificationStatus}
            submitUserTotpFactor={this.props.submitUserTotpFactor}
            user={this.props.user}
            errorMessage={recoveryFactor.totp.errorMessage}
          />
        )}
        {showRemoveTotpFlow && (
          <AuthFactorRemovalConfirmationModal
            show={showRemoveTotpFlow}
            cancelRemoveFactorFlow={this.cancelRemoveTotpFlow}
            factorDetails={factorDetails}
            factorType={factorType}
            removeFactor={this.props.removeTotpFactor}
          />
        )}
      </div>
    );
  }
}

TotpAccountSecurity.propTypes = {
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string
  }),
  isConfigurable: PropTypes.bool.isRequired,
  removeTotpFactor: PropTypes.func.isRequired,
  setTotpAcquireApp: PropTypes.func.isRequired,
  setTotpFactor: PropTypes.func.isRequired,
  setTotpFactorCancel: PropTypes.func,
  setTotpVerificationStatus: PropTypes.func,
  submitTotpVerificationCode: PropTypes.func.isRequired,
  submitUserTotpFactor: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  totpFactor: PropTypes.object.isRequired,
  totpVerificationStatus: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userTotpFactorChanging: PropTypes.func.isRequired
};

export default withTranslation()(TotpAccountSecurity);
