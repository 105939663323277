import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SubmitButton from "@cx/ui/SubmitButton";
import { isVerificationCodeValid } from "../../utils/validationUtil";
import "./_setTotpFactorFlow.scss";
import NumericInput from "@cx/ui/NumericInput";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

const EnterTotpVerificationCodePage = (props) => {
  const [isEnteredVerificationCodeValid, setIsEnteredVerificationCodeValid] =
    useState(true);
  const [verificationCode, setVerificationCode] = useState("");

  const onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-totp-flow-button",
      "Authentication App Flow Canceled",
      "Enter Authentication App Verification Code Page"
    );
    props.setTotpVerificationStatus({
      isVerificationCodeValid: true
    });
    setIsEnteredVerificationCodeValid(false);
    setVerificationCode("");
    props.onCancel();
  };

  const onBack = (event) => {
    reportElementClickedToGTM(
      "totp-flow-enter-code-back-button",
      "Authentication App Flow Back To Secret",
      "Enter Authentication App Verification Code Page"
    );
    props.setTotpFactor();
  };

  const setVerificationCodeOnChange = (event) => {
    setVerificationCode(event.target.value);
    // We will only put the "invalid" up when they submit a code, so when they change it, let's clear the invalid state
    // both at the local level and the parent level
    setIsEnteredVerificationCodeValid(true);
    props.setTotpVerificationStatus({
      isVerificationCodeValid: true
    });
  };

  const verificationCodeKeyDown = (event, isLoading) => {
    if (event.key === "Enter" && !isLoading) {
      submitVerificationCode();
    }
  };

  const submitVerificationCode = () => {
    reportElementClickedToGTM(
      "submit-verification-code-button",
      "Authentication App Code Submitted",
      "Authentication App Verification Code Page"
    );
    if (validateVerificationCode()) {
      props.verifyTotpFactor(verificationCode);
    }
  };

  const validateVerificationCode = () => {
    const isValid = isVerificationCodeValid(verificationCode);
    setIsEnteredVerificationCodeValid(isValid);
    return isValid;
  };

  const t = props.t;

  return (
    <>
      <Row>
        <Col xs={9} sm={9} md={9} lg={9}>
          <h4 id="totp-usage-text">
            {t("security-tab.totp-configure-verification-text")}
          </h4>
        </Col>
      </Row>

      <div>
        <Row>
          <Col xs={6} sm={4} md={4} lg={3}>
            <div>
              <NumericInput
                htmlId="verification-code-input"
                label={""}
                name="verification-code"
                minLength={6}
                maxLength={6}
                onChange={setVerificationCodeOnChange}
                onKeyDown={(event) => {
                  verificationCodeKeyDown(
                    event,
                    props.totpVerificationStatus.isLoading.submitCode
                  );
                }}
                onBlur={validateVerificationCode}
                value={verificationCode}
                error={
                  props.totpVerificationStatus.isVerificationCodeValid
                    ? isEnteredVerificationCodeValid
                      ? null
                      : t("messages.errors.verification-code-length")
                    : t("messages.errors.verification-code-invalid")
                }
              />
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col xs={6} sm={6} md={6} lg={6}>
          <div className="back-button">
            <SubmitButton
              htmlId="totp-flow-enter-code-back-button"
              className="recovery-page-button"
              buttonStyle="secondary"
              onClick={onBack}
            >
              {t("common.back")}
            </SubmitButton>
          </div>
        </Col>
        <Col xs={6} sm={6} md={6} lg={6}>
          <div id="totp-factor-page-buttons-group">
            <SubmitButton
              htmlId="cancel-totp-flow-button"
              className="recovery-page-button"
              buttonStyle="link"
              onClick={onCancel}
            >
              {t("common.cancel")}
            </SubmitButton>

            <SubmitButton
              htmlId="submit-verification-code"
              className="recovery-page-button"
              buttonStyle="primary"
              disabled={
                !isEnteredVerificationCodeValid ||
                props.totpVerificationStatus.isLoading.submitCode
              }
              onClick={submitVerificationCode}
              isLoading={props.totpVerificationStatus.isLoading.submitCode}
              loadingText={t("common.verifying")}
            >
              <Trans i18nKey="common.verify" />
            </SubmitButton>
          </div>
        </Col>
      </Row>
    </>
  );
};
EnterTotpVerificationCodePage.propTypes = {
  onCancel: PropTypes.func.isRequired,
  setTotpFactor: PropTypes.func.isRequired,
  setTotpVerificationStatus: PropTypes.func.isRequired,
  t: PropTypes.func,
  totpVerificationStatus: PropTypes.object,
  verifyTotpFactor: PropTypes.func.isRequired
};
export default withTranslation()(EnterTotpVerificationCodePage);
