import { ATTACH_SOLUTION, ATTACHING_SOLUTION_INDICATOR } from "./actionTypes";
import {
  displayAlertMessage,
  ERROR_MESSAGES,
  hideAlertMessage
} from "./statusActions";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../utils/MessageTypes";
import { createSolutionLinkFromAuthCode } from "../api/bff";
import i18n from "../i18n";
import appConfig from "../config/appConfig";

export function attachSolutionSuccess(solution) {
  return { type: ATTACH_SOLUTION, solution };
}

function attachingSolution(solutionId, isAttaching) {
  return {
    type: ATTACHING_SOLUTION_INDICATOR,
    solution: { solutionId, isAttaching }
  };
}

export function attachSolution(attachableSolution, userIsStandard) {
  const solutionID =
    attachableSolution.solution.solutionId ||
    attachableSolution.solution.pairedSolution;

  const availableSolutions = appConfig.solutionConfig.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  return async function (dispatch) {
    try {
      dispatch(
        hideAlertMessage(ERROR_MESSAGES.SOLUTION_ATTACHMENT_SERVICE_ERROR)
      );
      dispatch(attachingSolution(solutionID, true));
      return await createSolutionLinkFromAuthCode(
        solutionID,
        attachableSolution.redirectUrl,
        attachableSolution.authorizationCode
      )
        .then((response) => {
          dispatch(attachingSolution(solutionID, false));

          // Check if user should get new about password change
          // We do this first as new messages go on top, so we want the general success message to be on top
          if (attachableSolution.solution.isLegacy && userIsStandard) {
            dispatch(
              displayAlertMessage({
                payload: {
                  text: i18n.t(
                    "messages.success.attached-password-change",
                    "Your security requirements have changed " +
                      "because of the recently attached CAI solution. You " +
                      "will be prompted to change your password on your next " +
                      "sign-in."
                  )
                },
                type: MESSAGE_TYPE.ALERT,
                severity: MESSAGE_SEVERITY.WARN
              })
            );
          }

          const attachedSolutions = response.data.linkedSolutions.map(
            (attachedId) => {
              const solution = availableSolutions.find((solution) => {
                return (
                  solution.solutionId &&
                  solution.solutionId.toUpperCase() === attachedId.toUpperCase()
                );
              });
              dispatch(attachSolutionSuccess(solution));
              return solution;
            }
          );

          let primarySolution;
          let pairedSolution;
          if (attachedSolutions.length > 1) {
            primarySolution = attachedSolutions[0];
            pairedSolution = attachedSolutions[1];
          } else {
            primarySolution = attachedSolutions[0];
          }

          let payload;
          if (pairedSolution) {
            payload = {
              text: i18n.t(
                "messages.success.solution-attached-plural",
                "{{solutionname1}} and {{solutionname2}} have been attached. Your solution attachments may take several minutes to complete and show up in the Bridge Bar Solution Switcher.",
                {
                  solutionname1: primarySolution.name,
                  solutionname2: pairedSolution.name
                }
              )
            };
          } else {
            payload = {
              text: i18n.t(
                "messages.success.solution-attached",
                "{{solutionname}} has been attached. Your solution attachment may take several minutes to complete and show up in the Bridge Bar Solution Switcher.",
                {
                  solutionname: primarySolution.name
                }
              )
            };
          }
          dispatch(
            displayAlertMessage({
              payload,
              type: MESSAGE_TYPE.ALERT,
              severity: MESSAGE_SEVERITY.SUCCESS
            })
          );
        })
        .catch((error) => {
          dispatch(
            attachingSolution(attachableSolution.solution.solutionId, false)
          );
          if (error.response && error.response.status === 409) {
            dispatch(
              displayAlertMessage({
                payload: ERROR_MESSAGES.SOLUTION_ATTACHMENT_ALREADY_ATTACHED,
                type: MESSAGE_TYPE.ALERT,
                severity: MESSAGE_SEVERITY.FAIL
              })
            );
          } else {
            dispatch(
              displayAlertMessage({
                payload: ERROR_MESSAGES.SOLUTION_ATTACHMENT_SERVICE_ERROR,
                type: MESSAGE_TYPE.ALERT,
                severity: MESSAGE_SEVERITY.FAIL
              })
            );
          }
        });
    } catch (err) {
      dispatch(
        attachingSolution(attachableSolution.solution.solutionId, false)
      );
      setTimeout(() => {
        dispatch(
          displayAlertMessage({
            payload: ERROR_MESSAGES.SOLUTION_ATTACHMENT_SERVICE_ERROR,
            type: MESSAGE_TYPE.ALERT,
            severity: MESSAGE_SEVERITY.FAIL
          })
        );
      }, 1500);
    }
  };
}
