import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SubmitButton from "@cx/ui/SubmitButton";
import { QRCodeSVG } from "qrcode.react";
import "./_setTotpFactorFlow.scss";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const TotpSecretPage = (props) => {
  const onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-totp-flow-button",
      "Authentication App Flow Canceled",
      "Authentication App Secret Page"
    );
    props.onCancel();
  };

  const onBack = (event) => {
    reportElementClickedToGTM(
      "totp-flow-secret-page-back-button",
      "Authentication App Flow Back To Acquire App",
      "Authentication App Secret Page"
    );
    props.setTotpAcquireApp();
  };

  const t = props.t;
  if (!props.flowStatus.totpSharedSecret) {
    return <LoadingIndicator htmlId="qr-code-loading" />;
  }
  return (
    <>
      <Grid>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6} htmlId="totp-secret-column">
            <Row className="totp-secret-usage-text">
              <div className="totp-secret-wrapper">
                <h4>{t("security-tab.totp-configure-usage-qr-code-text")}</h4>
              </div>
            </Row>
            <Row>
              <div className="totp-secret-wrapper">
                <QRCodeSVG
                  value={`otpauth://totp/Cox%20Automotive%20BridgeID:${props.user.profile.preferredUsername}?secret=${props.flowStatus.totpSharedSecret}`}
                  size={165}
                />
              </div>
            </Row>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6} htmlId="totp-secret-column">
            <Row className="totp-secret-usage-text">
              <div className="totp-secret-wrapper">
                <h4>{t("security-tab.totp-configure-usage-secret-text")}</h4>
              </div>
            </Row>
            <Row>
              <div id="totp-secret-code">
                <h3>{props.flowStatus.totpSharedSecret}</h3>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div className="back-button">
              <SubmitButton
                htmlId="totp-flow-secret-page-back-button"
                className="recovery-page-button"
                buttonStyle="secondary"
                onClick={onBack}
              >
                {t("common.back")}
              </SubmitButton>
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div id="totp-factor-page-buttons-group">
              <SubmitButton
                htmlId="cancel-totp-flow-button"
                className="recovery-page-button"
                buttonStyle="link"
                onClick={onCancel}
              >
                {t("common.cancel")}
              </SubmitButton>
              <SubmitButton
                htmlId="send-verification-code"
                className="recovery-page-button"
                buttonStyle="primary"
                onClick={props.submitUserTotpFactor}
              >
                {t("common.continue")}
              </SubmitButton>
            </div>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

TotpSecretPage.propTypes = {
  errorMessage: PropTypes.object,
  flowStatus: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  setTotpAcquireApp: PropTypes.func.isRequired,
  submitUserTotpFactor: PropTypes.func.isRequired,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  userTotpFactorChanging: PropTypes.func.isRequired
};

export default withTranslation()(TotpSecretPage);
