import environments from "./environments.json";
import solutionConfiguration from "./solution-configuration.json";
import getParameterByName from "../utils/urlUtil";

const url2env = {
  "localhost-null": "local-prod",
  "localhost-prod": "local-prod",
  "localhost-non-prod": "local-nonprod",
  "user-settings.dangerzone.coxautoservices.com-non-prod": "dz-nonprod",
  "integration.user-settings.dangerzone.coxautoservices.com-non-prod":
    "dz-nonprod-integration",
  "user-settings.dangerzone.coxautoservices.com-prod": "dz-prod",
  "user-settings.dangerzone.coxautoservices.com-null": "dz-prod",
  "integration.user-settings.dangerzone.coxautoservices.com-prod":
    "dz-prod-integration",
  "integration.user-settings.dangerzone.coxautoservices.com-null":
    "dz-prod-integration",
  "integration.user-settings.coxautoinc.com-null": "live-prod-integration",
  "integration.user-settings.coxautoinc.com-prod": "live-prod-integration",
  "integration.user-settings.coxautoinc.com-non-prod":
    "live-nonprod-integration",
  "user-settings.coxautoinc.com-null": "live-prod",
  "user-settings.coxautoinc.com-prod": "live-prod",
  "user-settings.coxautoinc.com-non-prod": "live-nonprod"
};

const host = window.location.hostname;
const partition =
  getParameterByName("partition", window.location.href) || "prod";

export function isProdPartition() {
  if (partition) {
    return partition.localeCompare("prod") === 0;
  }

  // default
  return true;
}

export const environment = url2env[host + "-" + partition] || "local-nonprod";

const solutionConfig = getSolutionConfig();
const appConfig = environments[environment];
appConfig.solutionConfig = solutionConfig;
appConfig.partition = partition;

export function isNotProduction() {
  return host.includes("dangerzone") || host.includes("localhost");
}

function getSolutionConfig() {
  if (isNotProduction()) {
    return solutionConfiguration.dangerzone[partition];
  } else {
    return solutionConfiguration.live[partition];
  }
}

export function getEnv() {
  if (host === "user-settings.dangerzone.coxautoservices.com") {
    return "dangerzone";
  }

  if (host === "user-settings.coxautoinc.com") {
    return "live";
  }

  if (host === "localhost" || host === "127.0.0.1") {
    return "local";
  }

  return "unknown";
}

export function getPartition() {
  return partition;
}

export default appConfig;
