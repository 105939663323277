import { combineReducers } from "redux";
import statusReducer from "./statusReducer";
import userReducer from "./userReducer";
import solutionReducer from "./solutionReducer";
import authReducer from "./authReducer";
import userFactorsReducer from "./userFactorsReducer";

const rootReducer = combineReducers({
  statusReducer,
  userReducer,
  solutionReducer,
  authReducer,
  userFactorsReducer
});

export default rootReducer;
