import React from "react";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import EmailInput from "@cx/ui/EmailInput";
import { isEmailValid } from "../../utils/validationUtil";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";
import { Trans } from "react-i18next";
import SetRecoveryEmailFlow from "../pages/SetRecoveryEmailFlow";
import AuthFactorRemovalConfirmationModal from "./authFactorRemovalConfirmationModal";
import { FLOW_STATE } from "../../utils/flowStates";

class EmailAccountRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearingRecoveryEmail: false
    };
  }

  startSetEmailFlow = (event) => {
    if (this.props.emailRecoveryFactor.email.emailAddress !== "") {
      if (this.props.emailRecoveryFactor.email.verified) {
        reportElementClickedToGTM(
          "Remove Email",
          "Opened Remove Email Modal",
          "Account Security"
        );
        this.props.userEmailChanging({
          state: FLOW_STATE.SHOW_REMOVE_EMAIL_FLOW
        });
      } else {
        this.props.userEmailChanging({
          state: FLOW_STATE.SHOW_SET_EMAIL_FLOW,
          page: 1
        });
      }
    } else {
      reportElementClickedToGTM(
        "Email Configure",
        "Opened Email Modal",
        "Account Security"
      );
      this.props.userEmailChanging({
        state: FLOW_STATE.SHOW_SET_EMAIL_FLOW,
        page: 1
      });
    }
  };

  cancelSetEmailFlow = () => {
    this.props.changeUserEmailCancel();
    this.props.userEmailChanging({
      state: FLOW_STATE.HIDE_SET_EMAIL_FLOW
    });
    // Avoid 429 when user cancels flow w/out verifying, and then adds
    // the same email address again
    if (this.props.emailRecoveryFactor.email.emailAddress !== "") {
      this.props.submitUserEmail("", true);
    }
  };

  cancelRemoveEmailFlow = () => {
    reportElementClickedToGTM(
      "No, keep this Email",
      "Cancel Remove Email",
      "Account Security"
    );
    this.props.userEmailChanging({
      state: FLOW_STATE.HIDE_REMOVE_EMAIL_FLOW
    });
  };

  clearRecoveryEmail = () => {
    this.setState({
      isClearingRecoveryEmail: true
    });
    this.props.submitUserEmail("");
    this.setState({
      isClearingRecoveryEmail: false
    });
  };

  validateEmail = (label, email) => {
    return !email || isEmailValid(email) ? (
      ""
    ) : (
      <Trans i18nKey="messages.errors.email-is-invalid" />
    );
  };

  verifyUserEmail = (verificationCode) => {
    this.props.submitEmailVerificationCode(
      this.props.emailRecoveryFactor.email.emailAddress,
      verificationCode
    );
  };

  render() {
    const recoveryFactor =
      this.props.emailRecoveryFactor === undefined
        ? {
            flowStatus: {},
            email: {
              emailAddress: "",
              verified: false,
              errorMessage: ""
            }
          }
        : this.props.emailRecoveryFactor;
    const showSetEmailFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state === FLOW_STATE.SHOW_SET_EMAIL_FLOW;
    const showRemoveEmailFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state === FLOW_STATE.SHOW_REMOVE_EMAIL_FLOW;
    const factorType = "EMAIL";
    const factorDetails = {
      removeFactorModalCancelLabel:
        "security-tab.email-remove-modal-cancel-label",
      removeFactorModalRemoveLabel:
        "security-tab.email-remove-modal-remove-label",
      removeFactorModalRemovingLabel:
        "security-tab.email-remove-modal-removing-label",
      removeFactorModalText: "security-tab.email-remove-modal-text",
      removeFactorModalTitle: "security-tab.email-remove-modal-title"
    };

    return (
      <div className="input-field-wrapper">
        <EmailInput
          displayLabel={false}
          getError={this.validateEmail}
          htmlId="email-input-field"
          label={<Trans i18nKey={"common.email"} />}
          className="input-field"
          name="emailInputField"
          onChange={() => {}} // This field is read-only
          value={
            recoveryFactor.email.verified
              ? recoveryFactor.email.emailAddress
              : ""
          }
          readOnly={true}
          addOnPosition="append"
          appendChild={
            <Button
              buttonStyle="secondary"
              htmlId="email-input-button"
              onClick={(e) => {
                this.startSetEmailFlow();
                e.currentTarget.blur();
              }}
            >
              {recoveryFactor.email.emailAddress !== "" ? (
                <Trans i18nKey="common.remove" />
              ) : (
                <Trans i18nKey="common.configure" />
              )}
            </Button>
          }
        />
        {showSetEmailFlow && (
          <SetRecoveryEmailFlow
            dispatch={this.props.dispatch}
            timer={this.props.timer}
            show={showSetEmailFlow}
            onCancel={this.cancelSetEmailFlow}
            onClearEmailSuccess={this.clearRecoveryEmail}
            changeUserEmail={this.props.submitUserEmail}
            verifyUserEmail={this.verifyUserEmail}
            page={recoveryFactor.flowStatus.page}
            unverifiedEmail={recoveryFactor.flowStatus.unverifiedEmail}
            emailVerificationStatus={this.props.emailVerificationStatus}
            setEmailVerificationStatus={this.props.setEmailVerificationStatus}
            errorMessage={recoveryFactor.email.errorMessage}
            clearUserEmailAlreadyInUseStatus={
              this.props.clearUserEmailAlreadyInUseStatus
            }
          />
        )}
        {showRemoveEmailFlow && (
          <AuthFactorRemovalConfirmationModal
            show={showRemoveEmailFlow}
            cancelRemoveFactorFlow={this.cancelRemoveEmailFlow}
            factorDetails={factorDetails}
            factorType={factorType}
            submitUserFactor={this.props.submitUserEmail}
          />
        )}
      </div>
    );
  }
}

EmailAccountRecovery.propTypes = {
  changeUserEmailCancel: PropTypes.func.isRequired,
  clearUserEmailAlreadyInUseStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  emailRecoveryFactor: PropTypes.object.isRequired,
  emailVerificationStatus: PropTypes.object,
  setEmailVerificationStatus: PropTypes.func,
  submitEmailVerificationCode: PropTypes.func.isRequired,
  submitUserEmail: PropTypes.func.isRequired,
  t: PropTypes.func,
  timer: PropTypes.object.isRequired,
  userEmailChanging: PropTypes.func.isRequired
};

export default EmailAccountRecovery;
