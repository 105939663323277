import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import EmailInput from "@cx/ui/EmailInput";
import SubmitButton from "@cx/ui/SubmitButton";
import { isEmailValid } from "../../utils/validationUtil";
import "./_setRecoveryEmailFlow.scss";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

const EnterRecoveryEmailPage = (props) => {
  const [isEnteredEmailValid, setIsEnteredEmailValid] = useState(false);
  const [previousEmail, setPreviousEmail] = useState("");
  const [email, setEmail] = useState("");

  const onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-recovery-email-flow-button",
      "Email Verification Flow Canceled",
      "Enter Recovery Email Page"
    );
    setIsEnteredEmailValid(false);
    setPreviousEmail("");
    setEmail("");
    props.onCancel();
  };

  const setEmailOnChange = (event) => {
    if (props.errorMessage) {
      props.clearUserEmailAlreadyInUseStatus();
    }
    setEmail(event.target.value);
    setIsEnteredEmailValid(isEmailValid(event.target.value));
  };

  const emailInputKeyDown = (event) => {
    if (event.key === "Enter") {
      validateEventEmail(event);
      if (isEmailValid(event.target.value)) {
        sendVerificationCode();
      }
    }
  };

  const validateEventEmail = (event) => {
    if (previousEmail === "") {
      setIsEnteredEmailValid(true);
    }
    setPreviousEmail(email);
    setEmail(event.target.value);
    setIsEnteredEmailValid(isEmailValid(event.target.value));
  };

  const sendVerificationCode = () => {
    reportElementClickedToGTM(
      "send-verification-code-submit-button",
      "Verification Code Sent",
      "Enter Recovery Email Page"
    );
    props.sendVerificationCode(email);
  };

  const { t } = useTranslation();

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <p id="email-usage-text">
            {t("security-tab.email-configure-usage-text")}
          </p>
        </Col>
      </Row>

      <div>
        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div id="email-input-wrapper">
              <EmailInput
                htmlId="email-input"
                label={t("common.email")}
                name="emailInput"
                onChange={setEmailOnChange}
                onKeyDown={emailInputKeyDown}
                onBlur={validateEventEmail}
                value={email}
                getError={() => {
                  if (props.errorMessage) {
                    return props.errorMessage.text;
                  }
                  return isEnteredEmailValid || previousEmail === ""
                    ? ""
                    : t("messages.errors.email-is-invalid");
                }}
              />
            </div>
          </Col>
        </Row>
      </div>

      <div id="recovery-email-page-buttons-group">
        <SubmitButton
          htmlId="cancel-recovery-email-flow"
          className="recovery-page-button"
          buttonStyle="link"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </SubmitButton>
        <SubmitButton
          htmlId="send-verification-code"
          className="recovery-page-button"
          buttonStyle="primary"
          disabled={
            props.emailVerificationStatus.isLoading.sendCode ||
            !isEnteredEmailValid
          }
          onClick={sendVerificationCode}
          isLoading={props.emailVerificationStatus.isLoading.sendCode}
          loadingText={t("common.configure-verification-code-sending")}
        >
          {t("common.configure-verification-code-send")}
        </SubmitButton>
      </div>
    </>
  );
};

EnterRecoveryEmailPage.propTypes = {
  changeUserEmail: PropTypes.func.isRequired,
  clearUserEmailAlreadyInUseStatus: PropTypes.func.isRequired,
  emailVerificationStatus: PropTypes.object,
  errorMessage: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  sendVerificationCode: PropTypes.func.isRequired,
  t: PropTypes.func
};

export default EnterRecoveryEmailPage;
