import "core-js/stable";
import "react-app-polyfill/ie11";
import "./polyfills";
// Text encoding required for Edge and IE 11 to support Okta sign in widget.
import "fast-text-encoding";
// Webcrypto-shim required for IE11 to support Okta sign in widget.
import "webcrypto-shim";

import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore"; // eslint-disable-line import/default
import TagManager from "react-gtm-module";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import appConfig, { isNotProduction, getEnv } from "./config/appConfig";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import { BrowserRouter as Router } from "react-router-dom";

if (module.hot) {
  module.hot.accept();
}
const store = configureStore();
const tagManagerArgs = {
  gtmId: "GTM-KRJ9ZZW",
  dataLayer: {
    dataLayerReady: false
  }
};

TagManager.initialize(tagManagerArgs);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: isNotProduction()
      ? "5f7f6419ceea680a52dd6a45" // DZ
      : "5f7f64198fd1c90b794d5969", // LIVE

    // To disable use of anonymous users which will hit user pool limits of LD,
    // we have opted to use partition as an identifier for flagging purposes until a user
    // is identified through login. This means that unauthenticated parts of code can be
    // flagged by targeting partition as user key.
    user: {
      key: appConfig.partition,
      anonymous: false
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  });

  // Load Walkme
  const walkMe = document.createElement("script");
  walkMe.setAttribute("type", "text/javascript");
  walkMe.setAttribute("async", "true");
  walkMe.setAttribute(
    "src",
    isNotProduction()
      ? "https://cdn.walkme.com/users/cdea9851731b4e9289da2d4deebeaea3/test/walkme_cdea9851731b4e9289da2d4deebeaea3_https.js" // DZ
      : "https://cdn.walkme.com/users/cdea9851731b4e9289da2d4deebeaea3/walkme_cdea9851731b4e9289da2d4deebeaea3_https.js" // live
  );
  document.head.appendChild(walkMe);
  window._walkmeConfig = { smartLoad: true };

  // Load the bridge bar
  const bridgeBar = document.createElement("script");
  bridgeBar.id = "bridge-bar-script";
  bridgeBar.async = false;
  bridgeBar.defer = false;
  bridgeBar.src = ["dangerzone", "local"].includes(getEnv())
    ? "https://assets.bridge.dangerzone.coxautoservices.com/bridge-bar.js"
    : "https://assets.bridge.coxautoservices.com/bridge-bar.js";
  document.body.appendChild(bridgeBar);

  // Reference your top level component directly instead of <Router> and the child <Route> below
  render(
    <LDProvider>
      <Provider store={store}>
        {/* TODO: Refactoring
          We are not using React Router as it should have been used and we need to
          either remove the Router or refactor the code to use it properly
        */}
        <Router basename="/" className="main-layout">
          <UnauthenticatedRoute />
        </Router>
      </Provider>
    </LDProvider>,
    document.getElementById("root")
  );

  // TODO: At one point we attempted to register a service worker, it hasn't been working for a while, so let's unregister them
  //  currently and then in the future let's remove this
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
})();
