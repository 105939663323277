import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

/* eslint-disable import/no-webpack-loader-syntax */
const resources = require("i18next-resource-store-loader!./locales/index.js");

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    detection: {
      order: ["navigator"]
    }
  });

export default i18n;
