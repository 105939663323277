import React, { useEffect, useState } from "react";
import AuthHelper from "./AuthLib";
import PropTypes from "prop-types";
import LoadingIndicatorCentered from "../reusableComponents/LoadingIndicatorCentered";

export const AuthWrapper = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    AuthHelper.getToken()
      .then((token) => {
        const windowHash = window.localStorage.getItem("URL_HASH");
        if (windowHash) {
          window.location.hash = windowHash;
          window.localStorage.removeItem("URL_HASH");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.code === "LOGIN_REQUIRED") {
          window.localStorage.setItem("URL_HASH", window.location.hash);
          AuthHelper.startInteractiveAuth();
        } else {
          // Unexpected/Undocumented error
          // At this time maybe track these errors and report back to core domain
          setLoading(false);
        }
      }, []);
  });

  if (loading) {
    return (
      <div>
        <LoadingIndicatorCentered htmlId="auth-pending" size="large" />
      </div>
    );
  } else {
    return <div id="auth-wrapper">{props.children}</div>;
  }
};
AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired
};
