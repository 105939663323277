import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import SubmitButton from "@cx/ui/SubmitButton";

import en from "react-phone-number-input/locale/en.json";
import fr from "react-phone-number-input/locale/fr.json";
import es from "react-phone-number-input/locale/es.json";

import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber
} from "react-phone-number-input";

import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { isVerificationCodeValid } from "../../utils/validationUtil";
import NumericInput from "@cx/ui/NumericInput";
import "./_setRecoveryPhoneFlow.scss";
import { Trans, withTranslation } from "react-i18next";
import { TIMER_START, TIMER_TICK, TIMER_STOP } from "../../actions/actionTypes";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

class SetRecoveryPhoneFlow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPhoneNumberValid: !!this.props.errorMessage,
      suppressError: true,
      isVerificationCodeValid: false,
      phoneNumber: "",
      verificationCode: "",
      previousVerificationCode: ""
    };
  }

  onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-recovery-phone-flow-button",
      "Phone Verification Flow Canceled",
      "Set Recovery Phone Page"
    );
    this.setState({
      isPhoneNumberValid: false,
      suppressError: true,
      isVerificationCodeValid: false,
      phoneNumber: "",
      verificationCode: "",
      previousVerificationCode: ""
    });
    this.props.onCancel();
  };

  setPhoneNumber = (value) => {
    if (this.props.errorMessage) {
      this.props.clearUserPhoneAlreadyInUseStatus();
    }
    this.setState({
      phoneNumber: value,
      isPhoneNumberValid: value && isValidPhoneNumber(value)
    });
  };

  phoneInputKeyDown = (event) => {
    if (event.key === "Enter") {
      this.onPhoneInputBlur(event);
      this.setState({ suppressError: false });
      if (event.target.value && isValidPhoneNumber(event.target.value)) {
        this.sendVerificationCode();
      }
    } else {
      this.setState({ suppressError: true });
    }
  };

  setVerificationCode = (event) => {
    this.setState({
      verificationCode: event.target.value,
      isVerificationCodeValid: isVerificationCodeValid(event.target.value)
    });
  };

  verificationCodeKeyDown = (event, isLoading) => {
    if (event.key === "Enter" && !isLoading) {
      this.onVerificationCodeBlur(event);
      if (isVerificationCodeValid(event.target.value)) {
        this.submitVerificationCode();
      }
    }
  };

  sendVerificationCode = () => {
    reportElementClickedToGTM(
      "send-verification-code-button",
      "Verification Code Sent",
      "Set Recovery Phone Page"
    );
    this.props.changeUserPhone(this.state.phoneNumber);
    this.startResendTimer();
  };

  resendVerificationCode = () => {
    reportElementClickedToGTM(
      "resend-code-button",
      "Verification Code Resent",
      "Set Recovery Phone Page"
    );
    this.props.changeUserPhone(this.state.phoneNumber);
    this.startResendTimer();
  };

  submitVerificationCode = () => {
    reportElementClickedToGTM(
      "submit-verification-code-button",
      "Verification Code Submitted",
      "Set Recovery Phone Page"
    );
    this.props.verifyUserPhone(this.state.verificationCode);
  };

  onPhoneInputBlur = (event) => {
    this.setState({
      suppressError: false,
      phoneNumber: event.target.value,
      isPhoneNumberValid: isValidPhoneNumber(event.target.value)
    });
  };

  onVerificationCodeBlur = (event) => {
    if (this.state.previousVerificationCode !== event.target.value) {
      this.props.togglePhoneVerificationStatus({
        isVerificationCodeValid: true
      });
    }

    this.setState({
      previousVerificationCode: this.state.verificationCode,
      verificationCode: event.target.value,
      isVerificationCodeValid: isVerificationCodeValid(event.target.value)
    });
  };

  startResendTimer = () => {
    const tick = () => {
      if (this.props.timer.countdown <= 0) {
        clearInterval(this.props.timer.timerRef);
        this.props.dispatch({ type: TIMER_STOP });
        this.props.togglePhoneVerificationStatus({
          isLoading: { resendCode: false }
        });
      } else {
        this.props.dispatch({ type: TIMER_TICK });
      }
    };

    clearInterval(this.props.timer.timerRef);
    const timer = setInterval(tick, 1000);
    this.props.dispatch({ type: TIMER_START, ref: timer });
  };

  render() {
    const { show } = this.props;

    return (
      <ModalDialog
        htmlId="set-recovery-phone-flow"
        show={show}
        displayCloseButton={false}
        className="set-recovery-phone-flow"
      >
        <div id="phone-modal-content">
          <h3>
            <Trans i18nKey="profile-tab.phone-configure-title" />
          </h3>
          {this.props.page === 1 && (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p id="recovery-phone-usage-text">
                  <Trans i18nKey="profile-tab.phone-configure-usage-text" />
                </p>
              </Col>
            </Row>
          )}

          {this.props.page === 1 && (
            <div>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div id="phone-input-wrapper">
                    <PhoneInput
                      id="phone-input-maskedInput"
                      international
                      name="phoneNumber"
                      labels={
                        this.props.language.startsWith("fr")
                          ? fr
                          : this.props.language.startsWith("es")
                          ? es
                          : en
                      }
                      defaultCountry="US"
                      placeholder={this.props.t(
                        "common.phone-number",
                        "Mobile phone"
                      )}
                      value={this.state.phoneNumber}
                      onChange={this.setPhoneNumber}
                      onKeyDown={this.phoneInputKeyDown}
                      onBlur={this.onPhoneInputBlur}
                    />
                    <span className={"inputError"}>
                      {!this.state.suppressError &&
                        (this.props.errorMessage
                          ? this.props.errorMessage.text
                          : this.state.isPhoneNumberValid
                          ? ""
                          : this.props.t("common.phone-number-invalid"))}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          )}

          {this.props.page === 2 && (
            <div>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p id="verification-code-sent-text">
                    <Trans
                      i18nKey="profile-tab.phone-configure-verification-text"
                      values={{
                        phonenumber: formatPhoneNumberIntl(
                          this.state.phoneNumber
                        )
                      }}
                    >
                      Your request has been processed. If you do not receive a
                      code on your mobile phone with number{" "}
                      {formatPhoneNumberIntl(this.state.phoneNumber)} after 30
                      seconds, you may resend the code.
                    </Trans>
                  </p>
                  <br />
                </Col>
              </Row>

              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div>
                    <NumericInput
                      htmlId="verification-code-input"
                      label={<Trans i18nKey="common.code" />}
                      name="verification-code"
                      minLength={6}
                      maxLength={6}
                      onChange={this.setVerificationCode}
                      onKeyDown={(event) => {
                        this.verificationCodeKeyDown(
                          event,
                          this.props.phoneVerificationStatus.isLoading
                            .submitCode
                        );
                      }}
                      onBlur={this.onVerificationCodeBlur}
                      value={this.state.verificationCode}
                      error={
                        this.props.phoneVerificationStatus
                          .isVerificationCodeValid ? (
                          this.state.isVerificationCodeValid ||
                          this.state.previousVerificationCode === "" ? null : (
                            <Trans i18nKey="messages.errors.verification-code-length" />
                          )
                        ) : (
                          <Trans i18nKey="messages.errors.verification-code-invalid" />
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <SubmitButton
                htmlId="resend-code-button"
                buttonStyle="link"
                size="default"
                className="resend-code-button"
                onClick={this.resendVerificationCode}
                disabled={this.props.timer.countdown !== 0}
              >
                {this.props.timer.countdown !== 0 ? (
                  <Trans
                    i18nKey="profile-tab.phone-configure-verification-code-resend-countdown"
                    values={{ timeRemaining: this.props.timer.countdown }}
                  />
                ) : (
                  <Trans i18nKey="profile-tab.phone-configure-verification-code-resend-label" />
                )}
              </SubmitButton>
            </div>
          )}

          <div id="recovery-phone-page-buttons-group">
            <SubmitButton
              htmlId="cancel-recovery-phone-flow"
              className="recovery-page-button"
              buttonStyle="link"
              onClick={this.onCancel}
            >
              <Trans i18nKey="common.cancel" />
            </SubmitButton>
            {this.props.page === 1 && (
              <SubmitButton
                htmlId="send-verification-code"
                className="recovery-page-button"
                buttonStyle="primary"
                disabled={
                  this.props.phoneVerificationStatus.isLoading.sendCode ||
                  !this.state.isPhoneNumberValid
                }
                onClick={this.sendVerificationCode}
                isLoading={
                  this.props.phoneVerificationStatus.isLoading.sendCode
                }
                loadingText={
                  <Trans i18nKey="profile-tab.phone-configure-verification-code-sending" />
                }
              >
                <Trans i18nKey="profile-tab.phone-configure-verification-code-send" />
              </SubmitButton>
            )}

            {this.props.page === 2 && (
              <SubmitButton
                htmlId="submit-verification-code"
                className="recovery-page-button"
                buttonStyle="primary"
                disabled={
                  !this.state.isVerificationCodeValid ||
                  this.props.phoneVerificationStatus.isLoading.submitCode
                }
                onClick={this.submitVerificationCode}
                isLoading={
                  this.props.phoneVerificationStatus.isLoading.submitCode
                }
                loadingText={<Trans i18nKey="common.verifying" />}
              >
                <Trans i18nKey="common.verify" />
              </SubmitButton>
            )}
          </div>
        </div>
      </ModalDialog>
    );
  }
}

SetRecoveryPhoneFlow.propTypes = {
  changeUserPhone: PropTypes.func.isRequired,
  clearUserPhoneAlreadyInUseStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  language: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  phoneVerificationStatus: PropTypes.object,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func,
  timer: PropTypes.object.isRequired,
  togglePhoneVerificationStatus: PropTypes.func,
  verifyUserPhone: PropTypes.func.isRequired
};

export default withTranslation()(SetRecoveryPhoneFlow);
