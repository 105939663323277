import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

const LoadingIndicatorCentered = ({
  htmlId,
  size = "large",
  position = "absolute"
}) => {
  return (
    <div
      style={{
        position,
        left: "50%",
        top: "25%",
        transform: "translate(-50%, -50%)"
      }}
    >
      <LoadingIndicator htmlId={htmlId} size={size} />
    </div>
  );
};

LoadingIndicatorCentered.propTypes = {
  htmlId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "large"])
};

export default LoadingIndicatorCentered;
