import ModalDialog from "@cx/ui/ModalDialog";
import PropTypes from "prop-types";
import SubmitButton from "@cx/ui/SubmitButton";
import React, { useState } from "react";
import { Trans } from "react-i18next";
import "./_authFactorRemovalConfirmationModal.scss";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

const AuthFactorRemovalConfirmationModal = (props) => {
  const [clearingRecoveryFactor, setClearingRecoveryFactor] = useState(false);

  const clearRecoveryFactor = () => {
    setClearingRecoveryFactor(true);

    if (props.removeFactor === undefined) {
      props.submitUserFactor("").finally(() => {
        setClearingRecoveryFactor(false);
      });
    } else {
      props.removeFactor().finally(() => {
        setClearingRecoveryFactor(false);
      });
    }

    reportElementClickedToGTM(
      "Yes, remove this " + props.factorType,
      "Confirmed Remove " + props.factorType,
      "Account Security"
    );
  };

  return (
    <ModalDialog
      htmlId="authFactor-remove-modal"
      show={props.show}
      header={
        <ModalDialog.Title>
          <Trans i18nKey={props.factorDetails.removeFactorModalTitle} />
        </ModalDialog.Title>
      }
      onHide={props.cancelRemoveFactorFlow}
    >
      <div id="authFactor-remove-modal-content">
        <p>
          <Trans i18nKey={props.factorDetails.removeFactorModalText} />
        </p>
        <div id="authFactor-remove-modal-buttons-area">
          <SubmitButton
            htmlId="confirm-remove-factor"
            className="remove-authFactor-button"
            buttonStyle="primary"
            onClick={clearRecoveryFactor}
            isLoading={clearingRecoveryFactor}
            loadingText={
              <Trans
                i18nKey={props.factorDetails.removeFactorModalRemovingLabel}
              />
            }
          >
            <Trans i18nKey={props.factorDetails.removeFactorModalRemoveLabel} />
          </SubmitButton>
          <SubmitButton
            htmlId="cancel-remove-factor"
            className="remove-authFactor-button"
            buttonStyle="link"
            onClick={props.cancelRemoveFactorFlow}
          >
            <Trans i18nKey={props.factorDetails.removeFactorModalCancelLabel} />
          </SubmitButton>
        </div>
      </div>
    </ModalDialog>
  );
};

AuthFactorRemovalConfirmationModal.propTypes = {
  cancelRemoveFactorFlow: PropTypes.func.isRequired,
  factorDetails: PropTypes.shape({
    removeFactorModalCancelLabel: PropTypes.string.isRequired,
    removeFactorModalRemoveLabel: PropTypes.string.isRequired,
    removeFactorModalRemovingLabel: PropTypes.string.isRequired,
    removeFactorModalText: PropTypes.string.isRequired,
    removeFactorModalTitle: PropTypes.string.isRequired
  }),
  factorType: PropTypes.string.isRequired,
  removeFactor: PropTypes.func,
  show: PropTypes.bool,
  submitUserFactor: PropTypes.func
};

export default AuthFactorRemovalConfirmationModal;
