import AuthClient from "@bridge/auth-js";
import Config from "../../config/appConfig";

let authPromise = undefined;

// Create an instance of the client.  The library allows multiple instances if you have different client id's, environments, or scopes.
// TODO we probably should create different clients for different resources
const { env, redirectUri, pkceClientId, scopes } = Config.auth;
const defaultAuthClient = new AuthClient({
  env,
  clientId: pkceClientId,
  redirectUrl: redirectUri,
  scopes
});

const getAuthClient = () => {
  return defaultAuthClient;
};

const getToken = async () => {
  if (authPromise) {
    return authPromise;
  }
  authPromise = getAuthClient().getAuth();
  authPromise.fin(() => (authPromise = undefined));
  return authPromise;
};

const startInteractiveAuth = () => {
  defaultAuthClient.startInteractiveAuth();
};

const AuthHelper = {
  getAuthClient,
  getToken,
  startInteractiveAuth
};

export default AuthHelper;
