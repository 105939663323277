import axios from "axios";
import appConfig from "../config/appConfig";
import AuthHelper from "../components/auth/AuthLib";

const SLSApiAxiosInstance = (function () {
  let instance;

  function createInstance() {
    const config = {
      headers: {
        "Content-Type": "application/vnd.coxauto.v2+json",
        Accept: "application/json"
      },
      withCredentials: true,
      baseURL: appConfig.bff.baseURL
    };
    return axios.create(config);
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

async function refreshAuthHeader() {
  const token = await AuthHelper.getToken();
  SLSApiAxiosInstance.getInstance().defaults.headers["Authorization"] =
    "Bearer " + token;
}

export async function createSolutionLinkFromAuthCode(
  solutionId,
  redirectUrl,
  authorizationCode
) {
  await refreshAuthHeader();
  return SLSApiAxiosInstance.getInstance().post("/sls/create-link", {
    solutionId,
    redirectUrl,
    authorizationCode
  });
}
