import {
  LOAD_USER_SUCCESS,
  CHANGE_PREFERRED_USER_NAME_SUCCESS,
  PREFERRED_USER_NAME_AVAILABLE,
  CLEAR_PREFERRED_USER_NAME_AVAILABILITY,
  PREFERRED_USER_NAME_UNAVAILABLE,
  CHANGE_USER_PRIMARY_EMAIL_SUCCESS,
  CHANGE_USER_PRIMARY_EMAIL_FAILURE,
  CLEAR_USER_PRIMARY_EMAIL_SUCCESS,
  CLEAR_USER_PRIMARY_EMAIL_FAILURE
} from "../actions/actionTypes";

export const AVAILABILITY = {
  AVAILABLE: 1,
  UNAVAILABLE: 2,
  NA: 3
};

const initialState = {
  user: {
    id: null,
    idpSpecificUserId: null,
    isRegistered: null,
    isDeleted: null,
    identity: {
      username: null,
      isActive: null,
      credentials: {
        password: {
          isLocked: null,
          isExpired: null,
          daysUntilExpired: null // This value is added as its needed in the UI Profile Page, This is not included in User API V3
        },
        authenticationFactors: {
          email: {
            emailAddress: "",
            unsubmittedEmailAddress: ""
          },
          sms: {
            flowStatus: null,
            phoneNumber: "",
            verified: false
          }
        }
      },
      passwordPolicy: null
    },
    profile: {
      preferredUsername: null,
      newUsernameAvailability: AVAILABILITY.NA,
      givenName: null,
      familyName: null,
      primaryEmail: null
    },
    solutionLinks: null
  }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER_SUCCESS: {
      const user = action.user;
      if (user.identity.credentials !== undefined && user.isRegistered) {
        if (
          action.user.identity.credentials.authenticationFactors !== undefined
        ) {
          if (
            action.user.identity.credentials.authenticationFactors.email ===
            undefined
          ) {
            user.identity.credentials.authenticationFactors.email = {
              emailAddress: "",
              unsubmittedEmailAddress: ""
            };
          } else {
            user.identity.credentials.authenticationFactors.email.unsubmittedEmailAddress =
              action.user.identity.credentials.authenticationFactors.email.emailAddress;
          }

          if (
            action.user.identity.credentials.authenticationFactors.sms ===
            undefined
          ) {
            user.identity.credentials.authenticationFactors.sms = {
              phoneNumber: "",
              verified: false
            };
          } else {
            user.identity.credentials.authenticationFactors.sms.phoneNumber =
              action.user.identity.credentials.authenticationFactors.sms.phoneNumber;
            user.identity.credentials.authenticationFactors.sms.verified = true;
          }
        } else {
          user.identity.credentials.authenticationFactors = {
            email: {
              emailAddress: "",
              unsubmittedEmailAddress: ""
            },
            sms: {
              phoneNumber: "",
              verified: false
            }
          };
        }
      }

      return {
        ...state,
        user
      };
    }

    case CHANGE_PREFERRED_USER_NAME_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            preferredUsername: action.username
          }
        }
      };
    }

    case PREFERRED_USER_NAME_AVAILABLE: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            newUsernameAvailability: AVAILABILITY.AVAILABLE
          }
        }
      };
    }

    case PREFERRED_USER_NAME_UNAVAILABLE: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            newUsernameAvailability: AVAILABILITY.UNAVAILABLE
          }
        }
      };
    }

    case CLEAR_PREFERRED_USER_NAME_AVAILABILITY: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            newUsernameAvailability: AVAILABILITY.NA
          }
        }
      };
    }

    case CHANGE_USER_PRIMARY_EMAIL_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            primaryEmail: action.primaryEmail
          }
        }
      };
    }

    case CLEAR_USER_PRIMARY_EMAIL_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          profile: {
            ...state.user.profile,
            primaryEmail: null
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}
