import React from "react";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";
import "./PhoneAccountRecovery.scss";
import "react-phone-number-input/style.css";
import { formatPhoneNumberIntl } from "react-phone-number-input";

import { reportElementClickedToGTM } from "../../api/gtm-analytics";
import { Trans, withTranslation } from "react-i18next";
import SetRecoveryPhoneFlow from "../pages/SetRecoveryPhoneFlow";
import AuthFactorRemovalConfirmationModal from "./authFactorRemovalConfirmationModal";
import { FLOW_STATE } from "../../utils/flowStates";

class PhoneAccountRecovery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isClearingRecoveryPhone: false
    };
  }

  startSetPhoneFlow = (event) => {
    if (this.props.phoneRecoveryFactor.sms.phoneNumber !== "") {
      if (this.props.phoneRecoveryFactor.sms.verified) {
        reportElementClickedToGTM(
          "Remove Phone",
          "Opened Remove Phone Modal",
          "Account Security"
        );
        this.props.userPhoneChanging({
          state: FLOW_STATE.SHOW_REMOVE_PHONE_NUMBER_FLOW
        });
      } else {
        this.props.userPhoneChanging({
          state: FLOW_STATE.SHOW_SET_PHONE_NUMBER_FLOW,
          page: 1
        });
      }
    } else {
      reportElementClickedToGTM(
        "Phone Configure",
        "Opened Phone Modal",
        "Account Security"
      );
      this.props.userPhoneChanging({
        state: FLOW_STATE.SHOW_SET_PHONE_NUMBER_FLOW,
        page: 1
      });
    }
  };

  cancelSetPhoneFlow = () => {
    this.props.changeUserPhoneCancel();
    this.props.userPhoneChanging({
      state: FLOW_STATE.HIDE_SET_PHONE_NUMBER_FLOW
    });
    // Avoid 429 when user cancels flow w/out verifying, and then adds
    // the same phone # again
    if (this.props.phoneRecoveryFactor.sms.phoneNumber !== "") {
      this.props.submitUserPhone("", true);
    }
  };

  cancelRemovePhoneFlow = () => {
    reportElementClickedToGTM(
      "No, keep this Phone Number",
      "Cancel Remove Phone",
      "Account Security"
    );
    this.props.userPhoneChanging({
      state: FLOW_STATE.HIDE_REMOVE_PHONE_NUMBER_FLOW
    });
  };

  clearRecoveryPhone = () => {
    this.setState({
      isClearingRecoveryPhone: true
    });
    this.props.submitUserPhone("");
    this.setState({
      isClearingRecoveryPhone: false
    });
  };

  verifyUserPhone = (verificationCode) => {
    this.props.submitPhoneVerificationCode(
      this.props.phoneRecoveryFactor.sms.phoneNumber,
      verificationCode
    );
  };

  render() {
    const recoveryFactor =
      this.props.phoneRecoveryFactor === undefined
        ? {
            flowStatus: {},
            sms: {
              phoneNumber: "",
              verified: false,
              errorMessage: ""
            }
          }
        : this.props.phoneRecoveryFactor;
    const showSetPhoneFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state === FLOW_STATE.SHOW_SET_PHONE_NUMBER_FLOW;
    const showRemovePhoneFlow =
      recoveryFactor.flowStatus !== undefined &&
      recoveryFactor.flowStatus.state ===
        FLOW_STATE.SHOW_REMOVE_PHONE_NUMBER_FLOW;

    const factorType = "SMS";
    const factorDetails = {
      removeFactorModalCancelLabel:
        "security-tab.phone-remove-modal-cancel-label",
      removeFactorModalRemoveLabel:
        "security-tab.phone-remove-modal-remove-label",
      removeFactorModalRemovingLabel:
        "security-tab.phone-remove-modal-removing-label",
      removeFactorModalText: "security-tab.phone-remove-modal-text",
      removeFactorModalTitle: "security-tab.phone-remove-modal-title"
    };
    return (
      <div className="input-field-wrapper">
        <TextInput
          displayLabel={false}
          addOnPosition="append"
          htmlId="phone-input-field"
          label={<Trans i18nKey={"common.phone-number"} />}
          name="textInputAppendChild"
          className="input-field" // TODO: extract these CSS stuff from _inputfield.scss
          onChange={() => {}} // This field is read-only
          placeholder=""
          value={
            recoveryFactor.sms.verified
              ? formatPhoneNumberIntl(recoveryFactor.sms.phoneNumber)
              : ""
          }
          readOnly={true}
          appendChild={
            <Button
              buttonStyle="secondary"
              htmlId="phone-input-button"
              onClick={(e) => {
                this.startSetPhoneFlow();
                e.currentTarget.blur();
              }}
            >
              {recoveryFactor.sms.phoneNumber !== "" ? (
                <Trans i18nKey="common.remove" />
              ) : (
                <Trans i18nKey="common.configure" />
              )}
            </Button>
          }
        />
        {showSetPhoneFlow && (
          <SetRecoveryPhoneFlow
            dispatch={this.props.dispatch}
            timer={this.props.timer}
            show={showSetPhoneFlow}
            onCancel={this.cancelSetPhoneFlow}
            onClearPhoneSuccess={this.clearRecoveryPhone}
            changeUserPhone={this.props.submitUserPhone}
            verifyUserPhone={this.verifyUserPhone}
            page={recoveryFactor.flowStatus.page}
            phoneVerificationStatus={this.props.phoneVerificationStatus}
            togglePhoneVerificationStatus={
              this.props.setPhoneVerificationStatus
            }
            clearUserPhoneAlreadyInUseStatus={
              this.props.clearUserPhoneAlreadyInUseStatus
            }
            errorMessage={recoveryFactor.sms.errorMessage}
            language={this.props.i18n.language}
          />
        )}

        {showRemovePhoneFlow && (
          <AuthFactorRemovalConfirmationModal
            show={showRemovePhoneFlow}
            cancelRemoveFactorFlow={this.cancelRemovePhoneFlow}
            factorDetails={factorDetails}
            factorType={factorType}
            submitUserFactor={this.props.submitUserPhone}
          />
        )}
      </div>
    );
  }
}

PhoneAccountRecovery.propTypes = {
  changeUserPhoneCancel: PropTypes.func.isRequired,
  clearUserPhoneAlreadyInUseStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string
  }),
  phoneRecoveryFactor: PropTypes.object.isRequired,
  phoneVerificationStatus: PropTypes.object,
  setPhoneVerificationStatus: PropTypes.func,
  submitPhoneVerificationCode: PropTypes.func.isRequired,
  submitUserPhone: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  timer: PropTypes.object.isRequired,
  userPhoneChanging: PropTypes.func.isRequired
};

export default withTranslation()(PhoneAccountRecovery);
