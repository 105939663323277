import { ATTACH_SOLUTION } from "../actions/actionTypes";
import appConfig from "../config/appConfig";

const _ = require("lodash");

const initialState = {
  attachedSolutions: [],
  unattachedSolutions: appConfig.solutionConfig.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  )
};

export default function solutionReducer(state = initialState, action) {
  switch (action.type) {
    case ATTACH_SOLUTION: {
      const newAttachedSolutions = [...state.attachedSolutions];
      const primarySolutionId = (
        action.solution.solutionId || action.solution.pairedSolution
      ).toUpperCase();
      const foundSolution = state.unattachedSolutions.find((solution) => {
        return (
          solution.solutionId &&
          solution.solutionId.toUpperCase() === primarySolutionId
        );
      });

      // TODO: FIX THIS BUG, this was put in as a hack to handle this being called twice
      if (!foundSolution) {
        return state;
      }

      newAttachedSolutions.push(foundSolution);

      const foundWhiteLabelSolution = state.unattachedSolutions.find(
        (solution) => {
          return (
            solution.pairedSolution &&
            solution.pairedSolution.toUpperCase() === primarySolutionId
          );
        }
      );
      if (foundWhiteLabelSolution !== undefined) {
        newAttachedSolutions.push(foundWhiteLabelSolution);
      }

      const newUnattachedSolutions = _.difference(
        [...state.unattachedSolutions],
        newAttachedSolutions
      );

      return {
        ...state,
        attachedSolutions: newAttachedSolutions.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        ),
        unattachedSolutions: newUnattachedSolutions
      };
    }

    default:
      return state;
  }
}
