import React from "react";
import AttachedSolutions from "../reusableComponents/attachedSolutions";
import UnattachedSolutions from "../reusableComponents/unattachedSolutions";
import PropTypes from "prop-types";
import "../reusableComponents/_attachedSolutions.scss";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { connect } from "react-redux";

const AttachedSolutionsPage = ({
  user,
  attachedSolutions,
  isAttachingSolutions,
  unattachedSolutions,
  ldClient
}) => {
  const comingSoonSolutionIds = () => {
    return (
      ldClient?.variation("pdt.solution-coming-soon", "")?.split(", ") ?? []
    );
  };

  const comingSoonSolutions = () => {
    return unattachedSolutions.filter((solution) =>
      comingSoonSolutionIds().includes(solution.solutionId)
    );
  };

  const filteredUnattachedSolutions = () => {
    let effectiveBlacklist = [];

    if (ldClient) {
      const prodBlacklist = ldClient.variation(
        "pdt.mysettings.solution.blacklist",
        ""
      );
      if (
        prodBlacklist &&
        prodBlacklist !== "" &&
        prodBlacklist !== "Included"
      ) {
        effectiveBlacklist = prodBlacklist.split(", ");
      }
    }
    return unattachedSolutions.filter(
      (solution) =>
        !effectiveBlacklist.includes(solution.solutionId) &&
        !comingSoonSolutionIds().includes(solution.solutionId)
    );
  };

  return (
    <div className="attached-solutions-page-wrapper">
      <AttachedSolutions attachedSolutions={attachedSolutions} />
      <UnattachedSolutions
        isAttachingSolutions={isAttachingSolutions}
        unattachedSolutions={filteredUnattachedSolutions()}
        comingSoonSolutions={comingSoonSolutions()}
        user={user}
      />
    </div>
  );
};

AttachedSolutionsPage.propTypes = {
  attachedSolutions: PropTypes.array.isRequired,
  isAttachingSolutions: PropTypes.array.isRequired,
  ldClient: PropTypes.object.isRequired,
  unattachedSolutions: PropTypes.array.isRequired,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      preferredUsername: PropTypes.string.isRequired
    })
  }).isRequired
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    attachedSolutions: state.solutionReducer.attachedSolutions,
    unattachedSolutions: state.solutionReducer.unattachedSolutions,
    isAttachingSolutions: state.statusReducer.isAttachingSolutions
  };
}

// exposed for testing
export { AttachedSolutionsPage as AttachedSolutionsPageNoLDNoRedux };

const connectedAttachedSolutionsPage = connect(mapStateToProps)(
  AttachedSolutionsPage
);

export default withLDConsumer({})(connectedAttachedSolutionsPage);
