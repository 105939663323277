import appConfig from "../config/appConfig";
import { displayToastMessage } from "../actions/statusActions";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "./MessageTypes";
import { appDisplayName, passwordChangeQueryParam } from "./stringConstants";
import i18n from "../i18n.js";

export function displayPasswordChangeMessage() {
  return async function (dispatch) {
    dispatch(
      displayToastMessage({
        text: i18n.t(
          "messages.success.password-changed",
          "Password has been updated."
        ),
        type: MESSAGE_TYPE.TOAST,
        severity: MESSAGE_SEVERITY.SUCCESS
      })
    );
  };
}

export function changePassword() {
  const currentLocation = window.location.href;
  const successCallback = constructSuccessCallback(currentLocation);
  const url = new URL(appConfig.signin.passwordChangeURL);
  const searchParams = url.searchParams;
  searchParams.set("successCallback", successCallback);
  searchParams.set("abortCallback", currentLocation);
  searchParams.set("appDisplayName", appDisplayName);
  url.search = searchParams.toString();
  window.location.assign(url.toString());
}

function constructSuccessCallback(urlString) {
  const url = new URL(urlString);
  const searchParams = url.searchParams;
  // "yes" is a dummy value here; "1" or "x" would also work.
  searchParams.set(passwordChangeQueryParam, "yes");
  url.search = searchParams.toString();
  return url.toString();
}
