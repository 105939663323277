import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import "./_setRecoveryEmailFlow.scss";
import { Trans, withTranslation } from "react-i18next";
import EnterRecoveryEmailPage from "./EnterRecoveryEmailPage";
import EnterEmailVerificationCodePage from "./EnterEmailVerificationCodePage";
import { TIMER_START, TIMER_TICK, TIMER_STOP } from "../../actions/actionTypes";

class SetRecoveryEmailFlow extends React.Component {
  sendVerificationCode = (unverifiedEmail) => {
    this.props.changeUserEmail(unverifiedEmail);
    this.startResendTimer();
  };

  resendVerificationCode = () => {
    this.props.changeUserEmail(this.props.unverifiedEmail);
    this.startResendTimer();
  };

  startResendTimer = () => {
    const tick = () => {
      if (this.props.timer.countdown <= 0) {
        clearInterval(this.props.timer.timerRef);
        this.props.dispatch({ type: TIMER_STOP });
        this.props.setEmailVerificationStatus({
          isLoading: { resendCode: false }
        });
      } else {
        this.props.dispatch({ type: TIMER_TICK });
      }
    };

    clearInterval(this.props.timer.timerRef);
    const timer = setInterval(tick, 1000);
    this.props.dispatch({ type: TIMER_START, ref: timer });
  };

  onCancel = (event) => {
    this.setState({
      email: ""
    });
    this.props.onCancel();
  };

  render() {
    const { show } = this.props;

    return (
      <ModalDialog
        htmlId="set-recovery-email-flow"
        show={show}
        displayCloseButton={false}
        className="set-recovery-email-flow"
      >
        <div id="email-modal-content">
          <h3>
            <Trans i18nKey="security-tab.email-configure-title" />
          </h3>
          {this.props.page === 1 && (
            <EnterRecoveryEmailPage
              changeUserEmail={this.props.changeUserEmail}
              emailVerificationStatus={this.props.emailVerificationStatus}
              onCancel={this.props.onCancel}
              sendVerificationCode={this.sendVerificationCode}
              errorMessage={this.props.errorMessage}
              clearUserEmailAlreadyInUseStatus={
                this.props.clearUserEmailAlreadyInUseStatus
              }
            />
          )}
          {this.props.page === 2 && (
            <EnterEmailVerificationCodePage
              email={this.props.unverifiedEmail}
              emailVerificationStatus={this.props.emailVerificationStatus}
              onCancel={this.props.onCancel}
              resendVerificationCode={this.resendVerificationCode}
              setEmailVerificationStatus={this.props.setEmailVerificationStatus}
              verifyUserEmail={this.props.verifyUserEmail}
              timer={this.props.timer}
            />
          )}
        </div>
      </ModalDialog>
    );
  }
}

SetRecoveryEmailFlow.propTypes = {
  changeUserEmail: PropTypes.func.isRequired,
  clearUserEmailAlreadyInUseStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  emailVerificationStatus: PropTypes.object,
  errorMessage: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setEmailVerificationStatus: PropTypes.func,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func,
  timer: PropTypes.object.isRequired,
  unverifiedEmail: PropTypes.string, // not required on initial render, but required on "page 2"
  verifyUserEmail: PropTypes.func.isRequired
};

export default withTranslation()(SetRecoveryEmailFlow);
