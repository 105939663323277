import {
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING,
  SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS,
  SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS,
  SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS,
  LOAD_AUTH_FACTORS_SUCCESS
} from "./actionTypes";
import {
  displayAlertMessage,
  displayToastMessage,
  ERROR_MESSAGES,
  hideAlertMessage
} from "./statusActions";

import {
  deleteUserFactor,
  postUserFactor,
  postUserFactorVerificationCode
} from "../api/userFactorsApi";

import i18n from "../i18n";
import { MESSAGE_SEVERITY, MESSAGE_TYPE } from "../utils/MessageTypes";
import { USER_FACTOR_TYPE } from "../utils/userFactorTypes";
import { FLOW_STATE } from "../utils/flowStates";

export function loadAuthFactors(authFactors) {
  return function (dispatch) {
    dispatch(loadAuthFactorSuccess(authFactors));
  };
}

function loadAuthFactorSuccess(authFactors) {
  return { type: LOAD_AUTH_FACTORS_SUCCESS, authFactors };
}

export function userEmailChanging(flowStatus) {
  return { type: ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING, flowStatus };
}

export function changeUserEmailSuccess(emailAddress, verified) {
  return {
    type: ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS,
    emailAddress,
    verified
  };
}

function setUserEmailAlreadyInUseStatus() {
  return {
    type: ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS,
    errorMessage: ERROR_MESSAGES.AUTH_FACTORS_EMAIL_IN_USE_ERROR
  };
}

export function clearUserEmailAlreadyInUseStatus() {
  return function (dispatch) {
    dispatch({
      type: ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS,
      errorMessage: null
    });
  };
}

export function setUserEmailVerificationStatus(status) {
  return {
    type: SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS,
    status
  };
}

export function changeUserEmailCancel() {
  return { type: ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED };
}

export function submitUserEmail(newEmail, isOnCancel = false) {
  return async function (dispatch) {
    const trimmedNewEmail = newEmail.trim();
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    if (trimmedNewEmail === "") {
      return removeEmailRecoveryFactor(dispatch, isOnCancel);
    } else {
      return setEmailRecoveryFactor(trimmedNewEmail, dispatch);
    }
  };
}

function removeEmailRecoveryFactor(dispatch, isOnCancel = false) {
  return deleteUserFactor(USER_FACTOR_TYPE.EMAIL)
    .then((resp) => {
      dispatch(userEmailChanging({}));
      dispatch(changeUserEmailSuccess("", false));
      if (!isOnCancel) {
        displayEmailUpdateSuccessToast(dispatch);
      }
    })
    .catch((error) => {
      hideEmailModalAndShowError(dispatch);
    });
}

function setEmailRecoveryFactor(email, dispatch) {
  dispatch(
    setUserEmailVerificationStatus({
      isLoading: { sendCode: true, resendCode: true }
    })
  );
  return postUserFactor(USER_FACTOR_TYPE.EMAIL, email)
    .then((response) => {
      dispatch(
        setUserEmailVerificationStatus({
          isLoading: { sendCode: false, resendCode: false }
        })
      );
      if (response.data.isVerified) {
        dispatchUserEmailChanging(
          response.data.emailAddress,
          true,
          {
            state: FLOW_STATE.HIDE_SET_EMAIL_FLOW
          },
          dispatch
        );
        displayEmailUpdateSuccessToast(dispatch);
      } else {
        dispatchUserEmailChanging(
          response.data.emailAddress,
          false,
          {
            state: FLOW_STATE.SHOW_SET_EMAIL_FLOW,
            unverifiedEmail: email,
            page: 2
          },
          dispatch
        );
      }
    })
    .catch((error) => {
      dispatch(
        setUserEmailVerificationStatus({
          isLoading: { sendCode: false, resendCode: false }
        })
      );
      const errorStatus = error.response ? error.response.status : -1;
      switch (errorStatus) {
        case 409:
          dispatch(setUserEmailAlreadyInUseStatus());
          break;
        default:
          dispatch(
            userEmailChanging({
              state: FLOW_STATE.HIDE_SET_EMAIL_FLOW
            })
          );
          dispatch(changeUserEmailCancel());
          displayAuthFactorsErrorAlert(dispatch);
      }
    });
}

function dispatchUserEmailChanging(
  emailAddress,
  verified,
  flowStatus,
  dispatch
) {
  dispatch(changeUserEmailSuccess(emailAddress, verified));
  dispatch(userEmailChanging(flowStatus));
}

function displayEmailUpdateSuccessToast(dispatch) {
  displaySuccessToast(
    dispatch,
    i18n.t("messages.success.email-changed", "Email has been updated.")
  );
}

export function submitEmailVerificationCode(emailAddress, verificationCode) {
  return async function (dispatch, getState) {
    const trimmedCode = verificationCode.trim();

    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    dispatch(
      setUserEmailVerificationStatus({ isLoading: { submitCode: true } })
    );
    return postUserFactorVerificationCode(USER_FACTOR_TYPE.EMAIL, trimmedCode)
      .then((response) => {
        dispatch(
          setUserEmailVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        dispatch(changeUserEmailSuccess(emailAddress, true));
        dispatch(userEmailChanging({}));
        displayEmailUpdateSuccessToast(dispatch);
      })
      .catch((error) => {
        dispatch(
          setUserEmailVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        const errorStatus = error.response ? error.response.status : -1;
        switch (errorStatus) {
          case 422:
            dispatch(
              setUserEmailVerificationStatus({
                isVerificationCodeValid: false
              })
            );
            break;
          default:
            hideEmailModalAndShowError(dispatch);
            dispatch(changeUserEmailCancel());
        }
      });
  };
}

export function userPhoneChanging(flowStatus) {
  return { type: ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING, flowStatus };
}

export function changeUserPhoneSuccess(phoneNumber, verified) {
  return {
    type: ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS,
    phoneNumber,
    verified
  };
}

export function setUserPhoneVerificationStatus(status) {
  return {
    type: SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS,
    status
  };
}

export function changeUserPhoneCancel() {
  return { type: ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED };
}

function setUserPhoneAlreadyInUseStatus() {
  return {
    type: ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS,
    errorMessage: ERROR_MESSAGES.AUTH_FACTORS_PHONE_NUMBER_IN_USE_ERROR
  };
}

export function clearUserPhoneAlreadyInUseStatus() {
  return function (dispatch) {
    dispatch({
      type: ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS,
      errorMessage: null
    });
  };
}

export function submitUserPhone(newPhoneNumber, isOnCancel = false) {
  return async function (dispatch) {
    const trimmedNewPhoneNumber = newPhoneNumber.trim();
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    if (trimmedNewPhoneNumber === "") {
      return removeSmsRecoveryFactor(dispatch, isOnCancel);
    } else {
      return setSmsRecoveryFactor(trimmedNewPhoneNumber, dispatch);
    }
  };
}

function removeSmsRecoveryFactor(dispatch, isOnCancel = false) {
  return deleteUserFactor(USER_FACTOR_TYPE.SMS)
    .then((resp) => {
      dispatch(changeUserPhoneSuccess("", false));
      dispatch(userPhoneChanging({}));
      if (!isOnCancel) {
        displayPhoneUpdateSuccessToast(dispatch);
      }
    })
    .catch((error) => {
      hidePhoneModalAndShowError(dispatch);
    });
}

function setSmsRecoveryFactor(phoneNumber, dispatch) {
  dispatch(
    setUserPhoneVerificationStatus({
      isLoading: { sendCode: true }
    })
  );
  return postUserFactor(USER_FACTOR_TYPE.SMS, phoneNumber)
    .then((response) => {
      dispatch(
        setUserPhoneVerificationStatus({
          isLoading: { sendCode: false }
        })
      );
      if (response.data.isVerified) {
        dispatchUserPhoneChanging(
          response.data.phoneNumber,
          true,
          {
            state: FLOW_STATE.HIDE_SET_PHONE_NUMBER_FLOW
          },
          dispatch
        );
        displayPhoneUpdateSuccessToast(dispatch);
      } else {
        dispatchUserPhoneChanging(
          response.data.phoneNumber,
          false,
          {
            state: FLOW_STATE.SHOW_SET_PHONE_NUMBER_FLOW,
            page: 2
          },
          dispatch
        );
      }
    })
    .catch((error) => {
      dispatch(
        setUserPhoneVerificationStatus({
          isLoading: { sendCode: false }
        })
      );
      const errorStatus = error.response ? error.response.status : -1;
      switch (errorStatus) {
        case 409:
          dispatch(setUserPhoneAlreadyInUseStatus());
          break;
        default:
          dispatch(
            userPhoneChanging({
              state: FLOW_STATE.HIDE_SET_PHONE_NUMBER_FLOW
            })
          );
          dispatch(changeUserPhoneCancel());
          displayAuthFactorsErrorAlert(dispatch);
      }
    });
}

function dispatchUserPhoneChanging(
  phoneNumber,
  verified,
  flowStatus,
  dispatch
) {
  dispatch(changeUserPhoneSuccess(phoneNumber, verified));
  dispatch(userPhoneChanging(flowStatus));
}

function displayPhoneUpdateSuccessToast(dispatch) {
  displaySuccessToast(
    dispatch,
    i18n.t(
      "messages.success.phone-number-changed",
      "Phone number has been updated."
    )
  );
}

export function submitPhoneVerificationCode(phoneNumber, verificationCode) {
  return async function (dispatch, getState) {
    const trimmedCode = verificationCode.trim();

    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    dispatch(
      setUserPhoneVerificationStatus({
        isLoading: { submitCode: true }
      })
    );

    return postUserFactorVerificationCode(USER_FACTOR_TYPE.SMS, trimmedCode)
      .then((response) => {
        dispatch(
          setUserPhoneVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        dispatch(changeUserPhoneSuccess(phoneNumber, true));
        dispatch(userPhoneChanging({}));
        displayPhoneUpdateSuccessToast(dispatch);
      })
      .catch((error) => {
        dispatch(
          setUserPhoneVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        const errorStatus = error.response ? error.response.status : -1;
        switch (errorStatus) {
          case 422:
            // Invalid verification code
            dispatch(
              setUserPhoneVerificationStatus({
                isVerificationCodeValid: false
              })
            );
            break;
          default:
            hidePhoneModalAndShowError(dispatch);
            dispatch(changeUserPhoneCancel());
        }
      });
  };
}

export function userTotpFactorChanging(flowStatus) {
  return { type: ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING, flowStatus };
}

export function changeTotpFactorSuccess(verified) {
  return {
    type: ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS,
    verified
  };
}

export function setTotpFactorVerificationStatus(status) {
  return {
    type: SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS,
    status
  };
}

export function changeTotpFactorCancel() {
  return { type: ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED };
}

export function submitUserTotpFactor() {
  return async function (dispatch) {
    dispatch(setTotpFactorVerificationStatus({}));

    return dispatchTotpFactorChanging(
      false,
      {
        state: FLOW_STATE.SHOW_SET_TOTP_FLOW,
        page: 3
      },
      dispatch
    );
  };
}

export function setTotpAcquireApp() {
  return userTotpFactorChanging(false, {
    state: FLOW_STATE.SHOW_SET_TOTP_FLOW,
    page: 1
  });
}

export function setTotpFactor() {
  return async function (dispatch) {
    dispatch(setTotpFactorVerificationStatus({}));
    dispatchTotpFactorChanging(
      false,
      {
        state: FLOW_STATE.SHOW_SET_TOTP_FLOW,
        page: 2
      },
      dispatch
    );
    return postUserFactor(USER_FACTOR_TYPE.TOTP)
      .then((response) => {
        dispatch(setTotpFactorVerificationStatus({}));

        dispatchTotpFactorChanging(
          false,
          {
            state: FLOW_STATE.SHOW_SET_TOTP_FLOW,
            totpSharedSecret: response.data.totpSharedSecret,
            page: 2
          },
          dispatch
        );
      })
      .catch((error) => {
        dispatch(setTotpFactorVerificationStatus({}));
        const errorStatus = error.response ? error.response.status : -1;
        switch (errorStatus) {
          default:
            dispatch(
              userTotpFactorChanging({
                state: FLOW_STATE.HIDE_SET_TOTP_FLOW
              })
            );
            dispatch(changeTotpFactorCancel());
            displayAuthFactorsErrorAlert(dispatch);
        }
      });
  };
}

export function removeTotpFactor(isOnCancel = false) {
  return async function (dispatch) {
    return deleteUserFactor(USER_FACTOR_TYPE.TOTP)
      .then((resp) => {
        dispatch(userTotpFactorChanging({}));
        dispatch(changeTotpFactorSuccess(false));
        if (!isOnCancel) {
          displayTotpFactorUpdateSuccessToast(dispatch);
        }
      })
      .catch((error) => {
        hideTotpModalAndShowError(dispatch);
      });
  };
}

export function submitTotpFactorVerificationCode(verificationCode) {
  return async function (dispatch, getState) {
    const trimmedCode = verificationCode.trim();

    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    dispatch(
      setTotpFactorVerificationStatus({ isLoading: { submitCode: true } })
    );
    return postUserFactorVerificationCode(USER_FACTOR_TYPE.TOTP, trimmedCode)
      .then((response) => {
        dispatch(
          setTotpFactorVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        dispatch(changeTotpFactorSuccess(true));
        dispatch(userTotpFactorChanging({}));
        displayTotpFactorUpdateSuccessToast(dispatch);
      })
      .catch((error) => {
        dispatch(
          setTotpFactorVerificationStatus({
            isLoading: { submitCode: false }
          })
        );
        const errorStatus = error.response ? error.response.status : -1;
        switch (errorStatus) {
          case 422:
            dispatch(
              setTotpFactorVerificationStatus({
                isVerificationCodeValid: false
              })
            );
            break;
          default:
            hideTotpModalAndShowError(dispatch);
            dispatch(changeTotpFactorCancel());
        }
      });
  };
}

function dispatchTotpFactorChanging(verified, flowStatus, dispatch) {
  dispatch(changeTotpFactorSuccess(verified));
  dispatch(userTotpFactorChanging(flowStatus));
}

function displayTotpFactorUpdateSuccessToast(dispatch) {
  displaySuccessToast(
    dispatch,
    i18n.t(
      "messages.success.totp-factor-changed",
      "Authentication App Factor has been updated."
    )
  );
}

function displaySuccessToast(dispatch, message) {
  dispatch(
    displayToastMessage({
      text: message,
      type: MESSAGE_TYPE.TOAST,
      severity: MESSAGE_SEVERITY.SUCCESS
    })
  );
}

function hideEmailModalAndShowError(dispatch) {
  dispatch(userEmailChanging({}));
  displayAuthFactorsErrorAlert(dispatch);
}

function hidePhoneModalAndShowError(dispatch) {
  dispatch(userPhoneChanging({}));
  displayAuthFactorsErrorAlert(dispatch);
}

function hideTotpModalAndShowError(dispatch) {
  dispatch(userTotpFactorChanging({}));
  displayAuthFactorsErrorAlert(dispatch);
}

function displayAuthFactorsErrorAlert(dispatch) {
  dispatch(
    displayAlertMessage({
      payload: ERROR_MESSAGES.AUTH_FACTORS_SERVICE_ERROR,
      type: MESSAGE_TYPE.ALERT,
      severity: MESSAGE_SEVERITY.FAIL
    })
  );
}
