import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import { reportToGTM } from "../../api/gtm-analytics";
import { Trans } from "react-i18next";
import UsernameInputField from "../reusableComponents/UsernameInputField";
import { connect } from "react-redux";
import {
  cancelUsernameChange,
  changeUsername,
  verifyUsernameAvailability
} from "../../actions/userActions";
import { changePassword } from "../../utils/changePassword";
import { clearUsernameFieldStatus } from "../../actions/statusActions";
const AccountInformation = ({
  user,
  changeUsername,
  usernameStatus,
  clearUsernameFieldStatus,
  changePassword,
  verifyUsernameAvailability,
  cancelUsernameChange
}) => {
  const handleReportToGTM = () => {
    reportToGTM(
      "Change Password",
      "Clicked",
      "Open SignIn Change Password Page",
      "Profile"
    );
  };
  const { preferredUsername, newUsernameAvailability } = user.profile;
  return (
    <div className="profile-section">
      <h1>
        <Trans i18nKey="profile-tab.account-information" />
      </h1>
      <p id={"username-guide-text"}>
        <Trans i18nKey={"profile-tab.username-guide-text"} />:{" "}
        {"! # $ % & ' * + - / = ? ^ _ ` . { | } ~ @"}
      </p>
      <UsernameInputField
        initialValue={preferredUsername}
        newUsernameAvailability={newUsernameAvailability}
        changeUsername={changeUsername}
        verifyUsernameAvailability={verifyUsernameAvailability}
        cancelUsernameChange={cancelUsernameChange}
        clearUsernameFieldStatus={clearUsernameFieldStatus}
        usernameStatus={usernameStatus}
      />
      <div
        id="password-field-wrapper"
        className="input-wrapper inputField textInputAppendChild__formGroup form-group"
      >
        <label
          id="password-label"
          htmlFor="textInputAppendChild"
          className="inputField-label control-label"
        >
          <Trans i18nKey="common.password" />
        </label>
        <div>
          <Button
            buttonStyle="secondary"
            htmlId="change-password-button"
            onClick={() => {
              handleReportToGTM();
              setTimeout(() => changePassword(), 100);
            }}
          >
            <Trans i18nKey="profile-tab.change-password-label" />
          </Button>
        </div>
      </div>
    </div>
  );
};

AccountInformation.propTypes = {
  cancelUsernameChange: PropTypes.func,
  changePassword: PropTypes.func,
  changeUsername: PropTypes.func,
  clearUsernameFieldStatus: PropTypes.func,
  user: PropTypes.object,
  usernameStatus: PropTypes.object,
  verifyUsernameAvailability: PropTypes.func
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    usernameStatus: state.statusReducer.usernameStatus
  };
}
const mapDispatchToProps = {
  cancelUsernameChange,
  changePassword,
  changeUsername,
  clearUsernameFieldStatus,
  verifyUsernameAvailability
};

export { AccountInformation as AccountInformationNoRedux };
const connectedAccountInformation = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformation);

export default connectedAccountInformation;
