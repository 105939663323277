import React, { Component } from "react";
import InputField from "../common/InputField";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";

import { Trans } from "react-i18next";
import PropTypes from "prop-types";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../../utils/MessageTypes";

import "./_usernameInputField.scss";
import { AVAILABILITY } from "../../reducers/userReducer";

class UsernameInputField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUsername: "",
      resetUsernameToInitialValue: false
    };

    this.verifyUsername = this.verifyUsername.bind(this);
    this.cancelChangeUsername = this.cancelChangeUsername.bind(this);
    this.confirmChangeUsername = this.confirmChangeUsername.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.newUsernameAvailability !== this.props.newUsernameAvailability
    ) {
      if (
        this.props.newUsernameAvailability === AVAILABILITY.NA &&
        this.props.usernameStatus.type !== MESSAGE_TYPE.ALERT &&
        this.props.usernameStatus.severity !== MESSAGE_SEVERITY.FAIL
      ) {
        /* eslint-disable react/no-did-update-set-state */
        this.setState({
          resetUsernameToInitialValue: true
        });
        /* eslint-enable react/no-did-update-set-state */
      }
    }
  }

  verifyUsername(username) {
    // submit username
    this.props.clearUsernameFieldStatus();
    this.setState({
      newUsername: username,
      resetUsernameToInitialValue: false
    });
    if (username.toLowerCase() !== this.props.initialValue.toLowerCase()) {
      this.props.verifyUsernameAvailability(username);
    }
  }

  cancelChangeUsername() {
    this.props.cancelUsernameChange(this.props.initialValue);
  }

  confirmChangeUsername() {
    this.props.changeUsername(this.state.newUsername, this.props.initialValue);
  }

  render() {
    const errorMessage =
      this.props.usernameStatus !== undefined &&
      this.props.usernameStatus.severity === MESSAGE_SEVERITY.FAIL
        ? this.props.usernameStatus.message
        : "";

    const showConfirmationDialog =
      this.props.usernameStatus !== undefined &&
      this.props.usernameStatus.severity !== MESSAGE_SEVERITY.FAIL &&
      this.props.newUsernameAvailability === AVAILABILITY.AVAILABLE;

    const username = this.props.initialValue;

    return (
      <div>
        <InputField
          htmlId={"username-input-field"}
          label={<Trans i18nKey="common.username" />}
          initialValue={username}
          maxLength={100}
          errorMsg={errorMessage}
          submitAction={this.verifyUsername}
          clearFieldStatus={this.props.clearUsernameFieldStatus}
          emptyFieldMsg={<Trans i18nKey="messages.errors.username-empty" />}
          savingMessage={<Trans i18nKey="common.saving" />}
          resetValueToInitial={this.state.resetUsernameToInitialValue}
        />
        {showConfirmationDialog && (
          <ModalDialog
            htmlId="username-confirmation-dialog"
            show={showConfirmationDialog}
            header={
              <ModalDialog.Title>
                <Trans i18nKey="profile-tab.change-username" />
              </ModalDialog.Title>
            }
            onHide={this.cancelChangeUsername}
          >
            <div id="dialog-content">
              <p>
                <Trans i18nKey="profile-tab.change-username-confirmation-text" />
              </p>
              <div id="dialog-button-group">
                <SubmitButton
                  htmlId="confirm-change-username"
                  className="dialog-button"
                  buttonStyle="primary"
                  onClick={this.confirmChangeUsername}
                  loadingText={
                    <Trans i18nKey="profile-tab.changing-username" />
                  }
                >
                  <Trans i18nKey="profile-tab.change-username-confirm" />
                </SubmitButton>
                <SubmitButton
                  htmlId="cancel-change-username"
                  className="dialog-button"
                  buttonStyle="link"
                  onClick={this.cancelChangeUsername}
                >
                  <Trans i18nKey="profile-tab.change-username-cancel" />
                </SubmitButton>
              </div>
            </div>
          </ModalDialog>
        )}
      </div>
    );
  }
}

UsernameInputField.propTypes = {
  cancelUsernameChange: PropTypes.func,
  changeUsername: PropTypes.func,
  clearUsernameFieldStatus: PropTypes.func,
  initialValue: PropTypes.string,
  newUsernameAvailability: PropTypes.number,
  usernameStatus: PropTypes.object,
  verifyUsernameAvailability: PropTypes.func
};

export default UsernameInputField;
