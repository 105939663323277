export const isEmailValid = (emailAddress) => {
  // Source: http://emailregex.com/
  if (emailAddress === "") {
    return true;
  }
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(emailAddress);
};

export const isPhoneNumberValid = (value) => {
  const regex =
    /\(?([2-9]{1}\d{2})\)?([\s.-]{1}?)?([1-9]{1}\d{2})([\s.-]{1}?)?(\d{4})/;
  return regex.test(value);
};

export const isVerificationCodeValid = (value) => {
  const regex = /^[0-9]{6}$/;
  return regex.test(value);
};

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
}
