import React from "react";
import PropTypes from "prop-types";
import Alert from "@cx/ui/Alert";
import "./_alertMessage.scss";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../../utils/MessageTypes";

export default class AlertMessages extends React.Component {
  renderMessage = (message, i) => {
    const { hideAlertMessage } = this.props;
    if (
      message.type === MESSAGE_TYPE.ALERT &&
      message.severity !== MESSAGE_SEVERITY.NONE // never render a NONE message
    ) {
      let htmlIdValue;
      let typeValue;
      switch (message.severity) {
        case MESSAGE_SEVERITY.SUCCESS: {
          htmlIdValue = "successAlert";
          typeValue = "success";
          break;
        }
        case MESSAGE_SEVERITY.WARN: {
          htmlIdValue = "warningAlert";
          typeValue = "warning";
          break;
        }
        case MESSAGE_SEVERITY.FAIL: {
          htmlIdValue = "dangerAlert";
          typeValue = "danger";
          break;
        }
        case MESSAGE_SEVERITY.INFO:
        default: {
          htmlIdValue = "infoAlert";
          typeValue = "info";
        }
      }

      // React and i18next are both escaping html entities... as we know all the variables will be going through the
      // i18n escape sequence, we are ok with the dangerous set in this case.
      /* eslint-disable react/no-danger */
      return (
        <Alert
          htmlId={htmlIdValue}
          type={typeValue}
          key={message.payload.text + i}
          displayCloseButton
          onCloseClick={() => hideAlertMessage(message.payload)}
        >
          <span dangerouslySetInnerHTML={{ __html: message.payload.text }} />
        </Alert>
      );
      /* eslint-enable react/no-danger */
    }
  };

  render() {
    const { alertMessages } = this.props;
    return (
      <div className="message-list">
        {alertMessages.map((message, i) => this.renderMessage(message, i))}
      </div>
    );
  }
}

AlertMessages.propTypes = {
  alertMessages: PropTypes.array.isRequired,
  hideAlertMessage: PropTypes.func.isRequired
};
