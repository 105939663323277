import React from "react";
import PropTypes from "prop-types";
import SolutionCard from "./solutionCard";
import "./_unattachedSolutions.scss";
import { Trans } from "react-i18next";

const UnattachedSolutions = ({
  unattachedSolutions,
  isAttachingSolutions,
  comingSoonSolutions,
  user
}) => {
  return (
    <div
      id="unattached-solutions-container"
      className="unattached-solutions-container"
    >
      <h1>{<Trans i18nKey="solution-tab.attach-more-solutions-text" />}</h1>
      <ol>
        <li>{<Trans i18nKey="solution-tab.select-solution-text" />}</li>
        <li>{<Trans i18nKey="solution-tab.enter-solution-username-text" />}</li>
      </ol>
      <p>
        {
          <Trans
            i18nKey="solution-tab.attached-solutions-info-text"
            values={{
              username: user.profile.preferredUsername
            }}
          />
        }
      </p>
      <div id="unattached-solution-cards" className="unattached-solution-cards">
        {unattachedSolutions.map((solution) => (
          <SolutionCard
            key={solution.solutionKey}
            solution={solution}
            attached={false}
            isAttachingSolutions={isAttachingSolutions}
          />
        ))}
        {comingSoonSolutions.map((solution) => (
          <SolutionCard
            key={solution.solutionKey}
            solution={solution}
            attached={false}
            comingSoon={true}
          />
        ))}
      </div>
    </div>
  );
};

UnattachedSolutions.propTypes = {
  comingSoonSolutions: PropTypes.array.isRequired,
  isAttachingSolutions: PropTypes.array.isRequired,
  unattachedSolutions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

export default UnattachedSolutions;
