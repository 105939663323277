import React, { useState } from "react";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";
import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import SubmitButton from "@cx/ui/SubmitButton";
import { isVerificationCodeValid } from "../../utils/validationUtil";
import "./_setRecoveryEmailFlow.scss";
import NumericInput from "@cx/ui/NumericInput";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

const EnterEmailVerificationCodePage = (props) => {
  const [isEnteredVerificationCodeValid, setIsEnteredVerificationCodeValid] =
    useState(true);
  const [verificationCode, setVerificationCode] = useState("");

  const onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-recovery-email-flow-button",
      "Email Verification Flow Canceled",
      "Enter Email Verification Code Page"
    );
    props.setEmailVerificationStatus({
      isVerificationCodeValid: true
    });
    setIsEnteredVerificationCodeValid(false);
    setVerificationCode("");
    props.onCancel();
  };

  const setVerificationCodeOnChange = (event) => {
    setVerificationCode(event.target.value);
    // We will only put the "invalid" up when they submit a code, so when they change it, let's clear the invalid state
    // both at the local level and the parent level
    setIsEnteredVerificationCodeValid(true);
    props.setEmailVerificationStatus({
      isVerificationCodeValid: true
    });
  };

  const verificationCodeKeyDown = (event, isLoading) => {
    if (event.key === "Enter" && !isLoading) {
      submitVerificationCode();
    }
  };

  const resendVerificationCode = () => {
    reportElementClickedToGTM(
      "resend-code-button",
      "Email Verification Code Resent",
      "Enter Email Verification Code Page"
    );
    props.resendVerificationCode();
    // Since we are resending code, let's reset everything to empty without error
    setVerificationCode("");
    setIsEnteredVerificationCodeValid(true);
    props.setEmailVerificationStatus({
      isVerificationCodeValid: true
    });
  };

  const submitVerificationCode = () => {
    reportElementClickedToGTM(
      "submit-verification-code-button",
      "Email Verification Code Submitted",
      "Enter Email Verification Code Page"
    );
    if (validateVerificationCode()) {
      props.verifyUserEmail(verificationCode);
    }
  };

  const validateVerificationCode = () => {
    const isValid = isVerificationCodeValid(verificationCode);
    setIsEnteredVerificationCodeValid(isValid);
    return isValid;
  };

  const { t } = useTranslation();

  return (
    <>
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12}>
            <p id="verification-code-sent-text">
              <Trans
                i18nKey="security-tab.email-configure-verification-text"
                values={{
                  email: props.email
                }}
              >
                Your request has been processed. If you do not receive a code on
                your email account {props.email} after 30 seconds, you may
                resend the code.
              </Trans>
            </p>
            <br />
          </Col>
        </Row>

        <Row>
          <Col xs={6} sm={6} md={6} lg={6}>
            <div>
              <NumericInput
                htmlId="verification-code-input"
                label={<Trans i18nKey="common.code" />}
                name="verification-code"
                minLength={6}
                maxLength={6}
                onChange={setVerificationCodeOnChange}
                onKeyDown={(event) => {
                  verificationCodeKeyDown(
                    event,
                    props.emailVerificationStatus.isLoading.submitCode
                  );
                }}
                onBlur={validateVerificationCode}
                value={verificationCode}
                error={
                  props.emailVerificationStatus.isVerificationCodeValid
                    ? isEnteredVerificationCodeValid
                      ? null
                      : t("messages.errors.verification-code-length")
                    : t("messages.errors.verification-code-invalid")
                }
              />
            </div>
          </Col>
        </Row>

        <SubmitButton
          htmlId="resend-code-button"
          buttonStyle="link"
          size="default"
          className="resend-code-button"
          onClick={resendVerificationCode}
          disabled={props.timer.countdown !== 0}
        >
          {props.timer.countdown !== 0 ? (
            <Trans
              i18nKey="profile-tab.email-configure-verification-code-resend-countdown"
              values={{ timeRemaining: props.timer.countdown }}
            />
          ) : (
            <Trans i18nKey="profile-tab.email-configure-verification-code-resend-label" />
          )}
        </SubmitButton>
      </div>

      <div id="recovery-email-page-buttons-group">
        <SubmitButton
          htmlId="cancel-recovery-email-flow"
          className="recovery-page-button"
          buttonStyle="link"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </SubmitButton>
        <SubmitButton
          htmlId="submit-verification-code"
          className="recovery-page-button"
          buttonStyle="primary"
          disabled={
            !isEnteredVerificationCodeValid ||
            props.emailVerificationStatus.isLoading.submitCode
          }
          onClick={submitVerificationCode}
          isLoading={props.emailVerificationStatus.isLoading.submitCode}
          loadingText={<Trans i18nKey="common.verifying" />}
        >
          <Trans i18nKey="common.verify" />
        </SubmitButton>
      </div>
    </>
  );
};
EnterEmailVerificationCodePage.propTypes = {
  email: PropTypes.string,
  emailVerificationStatus: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  resendVerificationCode: PropTypes.func.isRequired,
  setEmailVerificationStatus: PropTypes.func.isRequired,
  t: PropTypes.func,
  timer: PropTypes.object.isRequired,
  verifyUserEmail: PropTypes.func.isRequired
};
export default EnterEmailVerificationCodePage;
