import React from "react";
import PropTypes from "prop-types";
import TextInput from "@cx/ui/TextInput";
import DelayedMessage from "./DelayedMessage";
import IconError from "@cx/ui/Icons/IconError";

import "./_inputField.scss";

export default class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue || "",
      emptyField: false
    };

    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { initialValue } = this.props;
    if (initialValue !== prevProps.initialValue) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        value: initialValue
      });
      /* eslint-enable react/no-did-update-set-state */
    }

    if (
      this.props.resetValueToInitial !== prevProps.resetValueToInitial &&
      this.props.resetValueToInitial
    ) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        value: initialValue
      });
      /* eslint-enable react/no-did-update-set-state */
    }
  }

  onChange(event) {
    const { value } = event.target;
    const isEmpty = value === "";
    if (this.state.emptyField !== isEmpty) {
      this.setState({
        emptyField: isEmpty,
        value
      });
    } else {
      this.setState({
        value
      });
    }
  }

  onBlur() {
    const { initialValue, submitAction, errorMsg } = this.props;
    const { emptyField, value } = this.state;
    const trimmedVal = value.trim();
    this.setState({
      value: trimmedVal
    });
    if (submitAction && typeof submitAction === "function") {
      if (this.props.required) {
        if (!emptyField && trimmedVal !== initialValue && trimmedVal !== "") {
          submitAction(trimmedVal);
        }
      } else {
        if (emptyField) {
          submitAction(trimmedVal);
        } else {
          if (trimmedVal !== initialValue || errorMsg) {
            submitAction(trimmedVal);
          }
        }
      }
    }
  }

  onKeyDown(event) {
    if (event.key === "Enter") {
      this.onBlur(event);
    } else {
      if (this.props.clearFieldStatus !== undefined) {
        this.props.clearFieldStatus();
      }
    }
  }

  render() {
    const {
      label,
      emptyFieldMsg,
      errorMsg,
      maxLength,
      htmlId,
      saving,
      savingMessage,
      savingMessageDelay,
      readonly,
      required
    } = this.props;
    const { value } = this.state;

    const error = required ? value === "" : errorMsg;

    const StatusMessage = () => {
      if (saving) {
        return (
          <DelayedMessage message={savingMessage} delay={savingMessageDelay} />
        );
      }
      if (error) {
        return (
          <div className="empty-field-msg-container">
            <IconError htmlId="IconError" className="error" />
            <div id={htmlId + "-error-msg"}>
              {value === "" ? emptyFieldMsg : errorMsg}
            </div>
          </div>
        );
      }
      return <React.Fragment />;
    };

    return (
      <div className="input-field-wrapper">
        <TextInput
          addOnPosition="append"
          htmlId={htmlId}
          label={label}
          name="textInputAppendChild"
          className={`input-field ${error ? "has-error" : ""}`}
          onChange={this.onChange}
          placeholder={""}
          value={this.state.value}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          maxLength={maxLength}
          readOnly={readonly}
          required={required}
        />
        <StatusMessage />
      </div>
    );
  }
}

InputField.propTypes = {
  clearFieldStatus: PropTypes.func,
  emptyFieldMsg: PropTypes.object,
  errorMsg: PropTypes.string,
  htmlId: PropTypes.string,
  initialValue: PropTypes.string,
  label: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  readonly: PropTypes.bool,
  required: PropTypes.bool,
  resetValueToInitial: PropTypes.bool,
  saving: PropTypes.bool,
  savingMessage: PropTypes.node,
  savingMessageDelay: PropTypes.number,
  submitAction: PropTypes.func
};
