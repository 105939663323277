import * as types from "../actions/actionTypes";

const initialState = {
  auth: {
    isLoggedIn: false,
    userId: null,
    expiring: false,
    preventExpiry: null
  },
  authClient: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_AUTH_CLIENT:
      return { ...state, authClient: action.value };
    case types.AUTH_SUCCESS:
      return {
        ...state,
        auth: {
          isLoggedIn: true,
          userId: action.userId,
          expiring: false,
          expiringIn: null,
          preventExpiry: null
        }
      };
    case types.AUTH_WILL_EXPIRE:
      return {
        ...state,
        auth: {
          expiring: true,
          expiringIn: action.expiringIn,
          preventExpiry: action.preventExpiry,
          isLoggedIn: true,
          userId: action.userId
        }
      };
    case types.AUTH_FAILURE:
    case types.AUTH_EXPIRED:
      return {
        ...state,
        auth: {
          expiring: false,
          isLoggedIn: false,
          expiringIn: null,
          preventExpiry: null,
          userId: null
        }
      };
    default:
      return state;
  }
};

export default authReducer;
