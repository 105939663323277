// NOTE: We are not using the `Tabs` from CX, as it does not provide needed
// functionality in how tab changes are handled.

import React, { useState, useEffect, Children } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./_tabs.scss";

/** A way to reveal one subtopic at a time */
const Tabs = ({ children, className, htmlId, type, onActiveTabChanged }) => {
  /* eslint-disable no-param-reassign */
  children = Children.toArray(children);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    children.find((child, index) => {
      return child.props.active && setActiveIndex(index);
    });
  }, [children]);

  return (
    <>
      <div
        className={classNames([
          "cx-tabs",
          type === "traditional" && "cx-tabs--alt",
          className
        ])}
        id={htmlId}
      >
        <ol className="cx-tablist" role="tablist">
          {children.map((child, index) => {
            const key = `${child.props.htmlId}_${index}`;
            return React.cloneElement(child, {
              active: activeIndex === index,
              htmlId: key,
              index,
              onActivate: (activeIndex) => {
                setActiveIndex(activeIndex);
                onActiveTabChanged(key, activeIndex);
              },
              key
            });
          })}
        </ol>
      </div>
      {children.map((child, index) => {
        if (activeIndex === index) {
          const key = `${child.props.htmlId}_${index}`;
          return React.cloneElement(child, {
            htmlId: key,
            index,
            panel: true,
            key
          });
        } else {
          return null;
        }
      })}
    </>
  );
};

Tabs.propTypes = {
  /** Children to be passed through. Only accepts instances of `Tab`. See [Tab](/components/Tab) for the list of props. */
  children: PropTypes.node.isRequired,

  /** Adds a custom CSS class to the component. */
  className: PropTypes.string,

  /** Globally unique and descriptive HTML ID. Used by QA for automated testing. */
  htmlId: PropTypes.string.isRequired,

  /** Sets the style of tab to be displayed. Accepts `default` and `traditional` */
  onActiveTabChanged: PropTypes.func,

  type: PropTypes.oneOf(["default", "traditional"]).isRequired
};

Tabs.defaultProps = {
  type: "default"
};

export default Tabs;
