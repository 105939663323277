import React from "react";
import PropTypes from "prop-types";
import SolutionCard from "./solutionCard";

const AttachedSolutions = ({ attachedSolutions }) => {
  return (
    <div id="attached-solutions-container">
      <img
        id="attached-solutions-container-logo"
        alt="Cox Automotive BridgeBar"
        src={require(`../../images/bridge-bar.svg`)}
      />
      {attachedSolutions.map((solution) => (
        <SolutionCard
          key={solution.solutionKey}
          solution={solution}
          attached={true}
        />
      ))}
    </div>
  );
};

AttachedSolutions.propTypes = {
  attachedSolutions: PropTypes.array.isRequired
};

export default AttachedSolutions;
