import { AuthWrapper } from "./auth/AuthWrapper";
import React from "react";
import App from "./App";
import CxThemeProvider from "@cx/ui/CxThemeProvider";

const UnauthenticatedRoute = (props) => {
  return (
    <AuthWrapper>
      <CxThemeProvider>
        <App />
      </CxThemeProvider>
    </AuthWrapper>
  );
};

export default UnauthenticatedRoute;
