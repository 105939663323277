import React from "react";
import PropTypes from "prop-types";
import "./_accountRecoveryMethods.scss";
import EmailAccountRecovery from "../reusableComponents/EmailAccountRecovery";
import PhoneAccountRecovery from "../reusableComponents/PhoneAccountRecovery";
import TotpAccountSecurity from "../reusableComponents/TotpAccountSecurity";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeUserEmailCancel,
  submitEmailVerificationCode,
  submitUserEmail,
  setUserEmailVerificationStatus,
  userEmailChanging,
  changeUserPhoneCancel,
  removeTotpFactor,
  submitUserPhone,
  submitPhoneVerificationCode,
  setUserPhoneVerificationStatus,
  clearUserEmailAlreadyInUseStatus,
  clearUserPhoneAlreadyInUseStatus,
  changeTotpFactorCancel,
  submitTotpFactorVerificationCode,
  submitUserTotpFactor,
  setTotpFactorVerificationStatus,
  userTotpFactorChanging,
  userPhoneChanging,
  setTotpFactor,
  setTotpAcquireApp
} from "../../actions/userFactorsActions";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import Card from "@cx/ui/Card";
import { Link } from "react-router-dom";
import { IconHelpOutline } from "@cx/ui/Icons";
import Alert from "@cx/ui/Alert";

const AccountRecoveryMethods = (props) => {
  const {
    emailRecoveryFactor,
    phoneRecoveryFactor,
    totpFactor,
    setTotpFactor,
    removeTotpFactor,
    submitUserEmail,
    submitUserPhone,
    submitUserTotpFactor,
    submitEmailVerificationCode,
    submitPhoneVerificationCode,
    submitTotpFactorVerificationCode,
    userEmailChanging,
    userPhoneChanging,
    userTotpFactorChanging,
    changeUserEmailCancel,
    changeUserPhoneCancel,
    changeTotpFactorCancel,
    emailVerificationStatus,
    phoneVerificationStatus,
    totpVerificationStatus,
    setEmailVerificationStatus,
    setPhoneVerificationStatus,
    setTotpAcquireApp,
    setTotpFactorVerificationStatus,
    clearUserEmailAlreadyInUseStatus,
    clearUserPhoneAlreadyInUseStatus,
    user,
    timer,
    dispatch
  } = props;

  return (
    <div className="account-recovery-section">
      {props.ldClient &&
      props.ldClient.variation("pdt.i18n-include-mfa", false) ? (
        <>
          <h2>
            <Trans i18nKey="security-tab.account-recovery-and-mfa-header" />
          </h2>
          <p className={"security-info"}>
            {/*
            This looks a little different from our usual translations, but doing it this way allows me to pass in a component
            Source: https://react.i18next.com/latest/trans-component#using-with-react-components
            */}
            <Trans i18nKey="security-tab.account-recovery-and-mfa-info">
              Email or mobile phone number is required for account recovery.
              Both methods can be used to verify your identity through
              <a
                href="https://www.coxautoinc.com/wp-content/uploads/2021/10/Common-User-FAQs.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                multi-factor authentication
              </a>
            </Trans>
          </p>
        </>
      ) : (
        <h1>
          <Trans i18nKey="security-tab.account-recovery-header" />
        </h1>
      )}

      <Card
        htmlId={"phone-recovery-factor"}
        header={<Trans i18nKey={"security-tab.phone-header"} />}
      >
        <div>
          <Trans
            i18nKey={"security-tab.account-recovery-and-mfa-phone-message"}
          />
          <div>
            <PhoneAccountRecovery
              phoneRecoveryFactor={phoneRecoveryFactor}
              submitUserPhone={submitUserPhone}
              submitPhoneVerificationCode={submitPhoneVerificationCode}
              userPhoneChanging={userPhoneChanging}
              changeUserPhoneCancel={changeUserPhoneCancel}
              phoneVerificationStatus={phoneVerificationStatus}
              setPhoneVerificationStatus={setPhoneVerificationStatus}
              dispatch={dispatch}
              timer={timer}
              clearUserPhoneAlreadyInUseStatus={
                clearUserPhoneAlreadyInUseStatus
              }
            />
          </div>
        </div>
      </Card>

      <Card
        htmlId={"email-recovery-factor"}
        header={<Trans i18nKey={"security-tab.email-header"} />}
      >
        <div>
          <Trans
            i18nKey={"security-tab.account-recovery-and-mfa-email-message"}
          />
          <div>
            <EmailAccountRecovery
              emailRecoveryFactor={emailRecoveryFactor}
              submitUserEmail={submitUserEmail}
              submitEmailVerificationCode={submitEmailVerificationCode}
              userEmailChanging={userEmailChanging}
              changeUserEmailCancel={changeUserEmailCancel}
              emailVerificationStatus={emailVerificationStatus}
              setEmailVerificationStatus={setEmailVerificationStatus}
              dispatch={dispatch}
              timer={timer}
              clearUserEmailAlreadyInUseStatus={
                clearUserEmailAlreadyInUseStatus
              }
            />
          </div>
        </div>
      </Card>

      {props.ldClient &&
      props.ldClient.variation("pdt.show-totp-factor", false) ? (
        <Card
          htmlId={"totp-recovery-factor"}
          header={<Trans i18nKey={"security-tab.totp-header"} />}
        >
          <div className={"recovery-factor-card"}>
            <Trans
              i18nKey={"security-tab.account-recovery-and-mfa-totp-message"}
            />
            {!(
              phoneRecoveryFactor.sms.verified ||
              emailRecoveryFactor.email.verified
            ) ? (
              <div className={"totp-alert"}>
                <Alert type={"warning"} htmlId="totp-info">
                  <Trans i18nKey={"security-tab.totp-configure-requirement"} />
                </Alert>
              </div>
            ) : (
              ""
            )}
            <div>
              <TotpAccountSecurity
                dispatch={dispatch}
                totpFactor={totpFactor}
                totpVerificationStatus={totpVerificationStatus}
                setTotpVerificationStatus={setTotpFactorVerificationStatus}
                setTotpFactor={setTotpFactor}
                setTotpAcquireApp={setTotpAcquireApp}
                setTotpFactorCancel={changeTotpFactorCancel}
                removeTotpFactor={removeTotpFactor}
                submitUserTotpFactor={submitUserTotpFactor}
                submitTotpVerificationCode={submitTotpFactorVerificationCode}
                user={user}
                userTotpFactorChanging={userTotpFactorChanging}
                // TOTP is only configurable if either email OR phone recovery factors are verified
                isConfigurable={
                  phoneRecoveryFactor.sms.verified ||
                  emailRecoveryFactor.email.verified
                }
              />
            </div>
          </div>
        </Card>
      ) : (
        <></>
      )}
    </div>
  );
};

AccountRecoveryMethods.propTypes = {
  changeTotpFactorCancel: PropTypes.func.isRequired,
  changeUserEmailCancel: PropTypes.func.isRequired,
  changeUserPhoneCancel: PropTypes.func.isRequired,
  clearUserEmailAlreadyInUseStatus: PropTypes.func.isRequired,
  clearUserPhoneAlreadyInUseStatus: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  emailRecoveryFactor: PropTypes.object.isRequired,
  emailVerificationStatus: PropTypes.object,
  ldClient: PropTypes.shape({
    variation: PropTypes.func.isRequired
  }).isRequired,
  phoneRecoveryFactor: PropTypes.object.isRequired,
  phoneVerificationStatus: PropTypes.object,
  removeTotpFactor: PropTypes.func.isRequired,
  setEmailVerificationStatus: PropTypes.func,
  setPhoneVerificationStatus: PropTypes.func,
  setTotpAcquireApp: PropTypes.func.isRequired,
  setTotpFactor: PropTypes.func.isRequired,
  setTotpFactorVerificationStatus: PropTypes.func,
  submitEmailVerificationCode: PropTypes.func.isRequired,
  submitPhoneVerificationCode: PropTypes.func.isRequired,
  submitTotpFactorVerificationCode: PropTypes.func.isRequired,
  submitUserEmail: PropTypes.func.isRequired,
  submitUserPhone: PropTypes.func.isRequired,
  submitUserTotpFactor: PropTypes.func.isRequired,
  timer: PropTypes.object,
  totpFactor: PropTypes.object.isRequired,
  totpVerificationStatus: PropTypes.object,
  user: PropTypes.object,
  userEmailChanging: PropTypes.func.isRequired,
  userPhoneChanging: PropTypes.func.isRequired,
  userTotpFactorChanging: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    phoneRecoveryFactor: state.userFactorsReducer.authFactors,
    emailRecoveryFactor: state.userFactorsReducer.authFactors,
    totpFactor: state.userFactorsReducer.authFactors,
    emailVerificationStatus: state.statusReducer.emailVerificationStatus,
    phoneVerificationStatus: state.statusReducer.phoneVerificationStatus,
    totpVerificationStatus: state.statusReducer.totpVerificationStatus,
    user: state.userReducer.user,
    timer: state.statusReducer.timer
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators(
      {
        changeUserEmailCancel,
        changeUserPhoneCancel,
        changeTotpFactorCancel,
        removeTotpFactor,
        submitEmailVerificationCode,
        submitPhoneVerificationCode,
        submitTotpFactorVerificationCode,
        submitUserEmail,
        submitUserPhone,
        submitUserTotpFactor,
        setTotpAcquireApp,
        setTotpFactor,
        setPhoneVerificationStatus: setUserPhoneVerificationStatus,
        setEmailVerificationStatus: setUserEmailVerificationStatus,
        setTotpFactorVerificationStatus,
        userEmailChanging,
        userPhoneChanging,
        userTotpFactorChanging,
        clearUserEmailAlreadyInUseStatus,
        clearUserPhoneAlreadyInUseStatus
      },
      dispatch
    )
  };
};

// exported to enable testing
export { AccountRecoveryMethods as AccountRecoveryMethodsNoRedux };

const connectedAccountRecoveryMethods = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountRecoveryMethods);

export default withLDConsumer({})(connectedAccountRecoveryMethods);
