import React, { useEffect, useState } from "react";
import AccountRecoveryMethods from "./AccountRecoveryMethods";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAllUserFactors } from "../../api/userFactorsApi";
import { loadAuthFactors } from "../../actions/userFactorsActions";
import Alert from "@cx/ui/Alert";
import { Trans } from "react-i18next";
import Button from "@cx/ui/Button";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import LoadingIndicatorCentered from "../reusableComponents/LoadingIndicatorCentered";

const AccountSecurity = (props) => {
  const { loadAuthFactors } = props;
  const [userFactorsRetrieved, setUserFactorsRetrieved] = useState(false);
  const [factorRetrievalFailed, setFactorRetrievalFailed] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);

  useEffect(() => {
    getAllUserFactors()
      .then((response) => {
        let authFactors = {
          flowStatus: {},
          email: {
            emailAddress: "",
            verified: false
          },
          sms: {
            phoneNumber: "",
            verified: false
          },
          totp: {
            verified: false
          }
        };
        for (const item of response.data.items) {
          if (item.type === "SMS") {
            authFactors = {
              ...authFactors,
              sms: {
                phoneNumber: item.phoneNumber,
                verified: item.isVerified
              }
            };
          } else if (item.type === "EMAIL") {
            authFactors = {
              ...authFactors,
              email: {
                emailAddress: item.emailAddress,
                verified: item.isVerified
              }
            };
          } else if (item.type === "TOTP") {
            authFactors = {
              ...authFactors,
              totp: {
                verified: item.isVerified
              }
            };
          }
        }
        loadAuthFactors(authFactors);
        setUserFactorsRetrieved(true);
      })
      .catch((reason) => {
        // eslint-disable-next-line no-console
        console.error("Failed to get user factors: " + reason);
        setFactorRetrievalFailed(true);
      });
  }, [attemptCount, loadAuthFactors]);

  if (userFactorsRetrieved) {
    return <AccountRecoveryMethods />;
  }

  if (factorRetrievalFailed) {
    return (
      <div>
        <br />
        <Alert htmlId="load-failed-message" type="danger">
          <h5>
            <Trans i18nKey="messages.errors.user-service-error" />
          </h5>
        </Alert>
        <Button
          buttonStyle="secondary"
          htmlId="try-again-button"
          onClick={() => setAttemptCount(attemptCount + 1)}
        >
          <Trans i18nKey="common.try-again" />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <LoadingIndicatorCentered
        htmlId="ff-load-pending"
        size="large"
        position="relative"
      />
    </div>
  );
};

AccountSecurity.propTypes = {
  authFactors: PropTypes.object,
  ldClient: PropTypes.shape({
    variation: PropTypes.func.isRequired
  }).isRequired,
  loadAuthFactors: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    authFactors: state.userFactorsReducer.authFactors
  };
}

const mapDispatchToProps = {
  loadAuthFactors
};

const connectedAccountSecurity = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSecurity);
export default withLDConsumer({})(connectedAccountSecurity);
