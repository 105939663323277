// This file centralizes HTTP requests using axios to keep calls short and consistent.
import appConfig from "../config/appConfig";
import axios from "axios";
import AuthHelper from "../components/auth/AuthLib";

const MEDIA_TYPE = "application/vnd.coxauto.v3+json";
const USERS_MINE = "/users/mine";

const UserApiAxiosInstance = (function () {
  let instance;

  function createInstance() {
    const config = {
      headers: {
        Accept: MEDIA_TYPE
      },
      withCredentials: true
    };
    const mergedConfig = Object.assign({}, appConfig.userApi, config);
    return axios.create(mergedConfig);
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

async function getAccessToken() {
  return await AuthHelper.getToken();
}

async function refreshAuthHeader() {
  const token = await getAccessToken();
  UserApiAxiosInstance.getInstance().defaults.headers["Authorization"] =
    "Bearer " + token;
}

export async function getUser() {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().get(USERS_MINE);
}

export async function postPreferredUsername(preferredUsername, etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/set-username",
    {
      preferredUsername
    },
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function checkUsernameAvailability(username) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/check-username-availability",
    {
      preferredUsername: username
    },
    {
      headers: {
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postPrimaryEmail(primaryEmail) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/profile/set-primary-email",
    {
      email: primaryEmail
    },
    {
      headers: {
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function deletePrimaryEmail() {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/profile/clear-primary-email",
    null,
    {
      headers: {
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postNewEmail(email, etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/set-email-factor",
    {
      emailAddress: email
    },
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postRemoveEmail(etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/remove-email-factor",
    {},
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postNewPhoneNumber(phoneNumber, etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/set-sms-factor",
    {
      phoneNumber,
      isVerified: false
    },
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postPhoneVerificationCode(passcode, etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/verify-sms-factor",
    {
      passcode
    },
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postRemovePhoneNumber(etag) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    USERS_MINE + "/identity/remove-sms-factor",
    {},
    {
      headers: {
        "If-Match": etag,
        "Content-Type": "application/vnd.coxauto.v3+json"
      }
    }
  );
}

export async function postSolutionLink(
  userId,
  solutionId,
  principalIdentifier
) {
  await refreshAuthHeader();
  return UserApiAxiosInstance.getInstance().post(
    `/users/id/${userId}/solution-links/link-solution-user`,
    {
      solutionId,
      principalIdentifier
    }
  );
}

export async function getUserETag() {
  await refreshAuthHeader();
  return getUser().then((resp) => {
    return resp.headers["etag"];
  });
}
