import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AlertMessages from "../common/AlertMessages";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

// NOTE: We are not using the `Tabs` from CX, as it does not provide needed
// functionality in how tab changes are handled.
import Tabs from "../Tabs";

import Tab from "@cx/ui/Tab";
import ProfilePage from "../profile";
import AttachedSolutions from "../attachedSolutions";
import AccountSecurity from "../accountSecurity";
import NotificationSettings from "../notificationSettings";
import { displayPasswordChangeMessage } from "../../utils/changePassword";
import { hideAlertMessage } from "../../actions/statusActions";
import "./_homePage.scss";
import ToastMessage from "../common/ToastMessage";
import bridgeBar from "../../utils/bridgeBar";
import { passwordChangeQueryParam } from "../../utils/stringConstants";
import getParameterByName from "../../utils/urlUtil";
import appConfig from "../../config/appConfig";
import { Trans } from "react-i18next";
import i18n from "i18next";
import { attachSolution } from "../../actions/solutionActions";
import { reportToGTM } from "../../api/gtm-analytics";

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    if (!this.props.user.isRegistered) {
      this.state = {
        tab: "attached-solutions"
      };
    } else {
      this.state = {
        tab: "profile"
      };
    }
  }

  componentDidMount() {
    bridgeBar.renderBridgeBar(i18n.language);

    const currentLocation = window.location.href;
    if (currentLocation.includes(passwordChangeQueryParam)) {
      this.props.displayPasswordChangeMessage();
      // remove password change flag from browser URL without refreshing
      // TODO: Do this in a separate function and call the function here
      const url = new URL(currentLocation);
      const searchParams = url.searchParams;
      searchParams.delete(passwordChangeQueryParam);
      url.search = searchParams.toString();
      window.history.replaceState(
        "replaceStateData",
        "replaceStateTitle",
        url.toString()
      );
    } else if (window.location.pathname.indexOf("/solutionauthcallback") >= 0) {
      const state = JSON.parse(
        atob(getParameterByName("state", window.location.href))
      );

      const authCode = getParameterByName("code", window.location.href);

      window.history.replaceState(
        "replaceStateData",
        "replaceStateTitle",
        "/?partition=" + appConfig.partition
      );

      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        tab: "attached-solutions"
      });
      /* eslint-enable react/no-did-mount-set-state */

      const isUserNotLegacy =
        this.props.user.identity.passwordPolicy !== "LEGACY";

      this.props.attachSolution(
        {
          solution: state.authenticatedSolution,
          redirectUrl: "https://" + window.location.hostname,
          authorizationCode: authCode
        },
        isUserNotLegacy
      );
    }

    this.setStateBasedOnTab();
  }

  setStateBasedOnTab() {
    if (window.location.hash.includes("#attached-solutions")) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        tab: "attached-solutions"
      });
      /* eslint-enable react/no-did-mount-set-state */
      this.replaceUrlWithPartitionOnly();
    }

    if (window.location.hash.includes("#account-security")) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        tab: "account-security"
      });
      /* eslint-enable react/no-did-mount-set-state */
      this.replaceUrlWithPartitionOnly();
    }

    if (window.location.hash.includes("#notifications")) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({
        tab: "notifications"
      });

      /* eslint-enable react/no-did-mount-set-state */
      this.replaceUrlWithPartitionOnly();
    }

    if (window.location.pathname.includes("authcallback")) {
      this.replaceUrlWithPartitionOnly();
    }
  }

  replaceUrlWithPartitionOnly() {
    if (window.location.href.includes("partition")) {
      if (appConfig.partition === "non-prod") {
        const cleanedUrl = "/?partition=" + appConfig.partition;
        window.history.replaceState("", "My Settings", cleanedUrl);
      } else {
        window.history.replaceState("", "My Settings", "/");
      }
    } else {
      window.history.replaceState("", "My Settings", "/");
    }
  }

  render() {
    const { user, alertMessages, hideAlertMessage } = this.props;

    const isTabActive = (val) => {
      return this.state.tab === val;
    };

    const onActiveTabChanged = (key, index) => {
      let newTab;
      if (key.includes("profileTab")) {
        newTab = "profile";
      } else if (key.includes("securityTab")) {
        newTab = "account-security";
      } else if (key.includes("solutionsTab")) {
        newTab = "attached-solutions";
      } else if (key.includes("notificationsTab")) {
        newTab = "notifications";
      }
      this.setState(
        {
          tab: newTab
        },
        () => {
          window.location.hash = "#" + this.state.tab;
        }
      );
      reportToGTM(
        `${newTab} tab`,
        "Clicked",
        `Switched to ${newTab} tab`,
        "User settings home page"
      );
    };

    return (
      <div className="home-page-wrapper">
        <ToastMessage />
        <React.Fragment>
          {/*
            This PageHeader is block referenced as part of our smoke test running in New Relic.  If we change anything
            inside this block, just be aware that the smoke test will very likely also need to be updated to match!
          */}
          <div className={"page-header"}>
            <h1>
              <Trans i18nKey="common.my-settings" /> - {user.profile.givenName}{" "}
              {user.profile.familyName} ({user.profile.preferredUsername})
            </h1>
          </div>
          {alertMessages.length !== 0 && (
            <AlertMessages
              alertMessages={alertMessages}
              hideAlertMessage={hideAlertMessage}
            />
          )}
          {!this.props.user.isRegistered ? (
            <Tabs htmlId="TabsDefault" onActiveTabChanged={onActiveTabChanged}>
              <Tab
                htmlId="solutionsTab"
                label={<Trans i18nKey="solution-tab.header" />}
                active={isTabActive("attached-solutions")}
              >
                <AttachedSolutions />
              </Tab>
              {this.props.ldClient.variation(
                "pdt.show-notifications-tab",
                false
              ) && (
                <Tab
                  htmlId="notificationsTab"
                  label={<Trans i18nKey="notifications-tab.header" />}
                  active={isTabActive("notifications")}
                >
                  <NotificationSettings />
                </Tab>
              )}
            </Tabs>
          ) : (
            <Tabs htmlId="TabsDefault" onActiveTabChanged={onActiveTabChanged}>
              <Tab
                htmlId="profileTab"
                label={<Trans i18nKey="profile-tab.header" />}
                active={isTabActive("profile")}
              >
                <ProfilePage />
              </Tab>
              <Tab
                htmlId="securityTab"
                label={<Trans i18nKey="security-tab.header" />}
                active={isTabActive("account-security")}
              >
                <AccountSecurity />
              </Tab>
              <Tab
                htmlId="solutionsTab"
                label={<Trans i18nKey="solution-tab.header" />}
                active={isTabActive("attached-solutions")}
              >
                <AttachedSolutions />
              </Tab>
              {this.props.ldClient.variation(
                "pdt.show-notifications-tab",
                false
              ) && (
                <Tab
                  htmlId="notificationsTab"
                  label={<Trans i18nKey="notifications-tab.header" />}
                  active={isTabActive("notifications")}
                >
                  <NotificationSettings />
                </Tab>
              )}
            </Tabs>
          )}
        </React.Fragment>
      </div>
    );
  }
}

HomePage.propTypes = {
  alertMessages: PropTypes.array.isRequired,
  attachSolution: PropTypes.func.isRequired,
  displayPasswordChangeMessage: PropTypes.func.isRequired,
  flags: PropTypes.object,
  hideAlertMessage: PropTypes.func.isRequired,
  ldClient: PropTypes.shape({
    variation: PropTypes.func.isRequired
  }).isRequired,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user,
    alertMessages: state.statusReducer.alertMessages
  };
}

const mapDispatchToProps = {
  displayPasswordChangeMessage,
  hideAlertMessage,
  attachSolution
};

const connectedHomePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);

export default withLDConsumer({})(connectedHomePage);
