import {
  ADD_ALERT_MESSAGE,
  ATTACHING_SOLUTION_INDICATOR,
  CHANGE_USERNAME_FIELD_FAIL_STATUS,
  CHANGE_PHONE_FIELD_STATUS,
  CHANGE_EMAIL_FIELD_STATUS,
  CHANGE_TOTP_FACTOR_FIELD_STATUS,
  HIDE_ALERT_MESSAGE,
  SHOW_TOAST_MESSAGE,
  TOGGLE_USER_PROFILE_RECOVERY_PHONE_VERIFICATION_STATUS,
  SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS,
  CLEAR_USERNAME_FIELD_STATUS,
  SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS,
  SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS,
  TIMER_START,
  TIMER_TICK,
  TIMER_STOP
} from "../actions/actionTypes";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../utils/MessageTypes";

const initialState = {
  alertMessages: [],
  toastMessage: undefined,
  phoneStatus: "",
  phoneVerificationStatus: {
    isVerificationCodeValid: true,
    isLoading: {
      sendCode: false,
      submitCode: false
    }
  },
  emailStatus: "",
  emailVerificationStatus: {
    isVerificationCodeValid: true,
    isLoading: {
      sendCode: false,
      submitCode: false
    }
  },
  totpStatus: "",
  totpVerificationStatus: {
    isVerificationCodeValid: true,
    isLoading: {
      submitCode: false
    }
  },
  usernameStatus: {
    type: MESSAGE_TYPE.FIELD,
    severity: MESSAGE_SEVERITY.NONE,
    message: ""
  },
  isAttachingSolutions: [],
  timer: {
    countdown: 30,
    timerRef: null
  }
};

export default function statusReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT_MESSAGE: {
      // If no id was passed, let's generate one
      if (!action.message.payload.id) {
        action.message.payload.id = new Date().getTime();
      }
      const existingMessage = state.alertMessages.find((message) => {
        return action.message.payload.id === message.payload.id;
      });
      if (existingMessage === undefined) {
        return {
          ...state,
          alertMessages: [action.message].concat(state.alertMessages)
        };
      } else {
        return state;
      }
    }

    case ATTACHING_SOLUTION_INDICATOR: {
      const newIsAttachingSolutions = state.isAttachingSolutions.slice();
      if (!action.solution.isAttaching) {
        newIsAttachingSolutions.splice(
          newIsAttachingSolutions.indexOf(action.solution.solutionId),
          1
        );
      } else {
        newIsAttachingSolutions.push(action.solution.solutionId);
      }

      return {
        ...state,
        isAttachingSolutions: newIsAttachingSolutions
      };
    }

    case HIDE_ALERT_MESSAGE: {
      const newMessages = [...state.alertMessages].filter((message) => {
        return message.payload.id !== action.message.id;
      });
      return {
        ...state,
        alertMessages: newMessages
      };
    }

    case SHOW_TOAST_MESSAGE: {
      return {
        ...state,
        toastMessage: action.message
      };
    }

    case CHANGE_USERNAME_FIELD_FAIL_STATUS:
      return {
        ...state,
        usernameStatus: {
          type: MESSAGE_TYPE.FIELD,
          severity: MESSAGE_SEVERITY.FAIL,
          message: action.message
        }
      };

    case CLEAR_USERNAME_FIELD_STATUS:
      return {
        ...state,
        usernameStatus: {
          type: MESSAGE_TYPE.FIELD,
          severity: MESSAGE_SEVERITY.NONE,
          message: ""
        }
      };

    case CHANGE_PHONE_FIELD_STATUS:
      return { ...state, phoneStatus: false };

    case CHANGE_EMAIL_FIELD_STATUS:
      return { ...state, emailStatus: false };

    case CHANGE_TOTP_FACTOR_FIELD_STATUS:
      return { ...state, totpStatus: false };

    case TOGGLE_USER_PROFILE_RECOVERY_PHONE_VERIFICATION_STATUS:
      return {
        ...state,
        phoneVerificationStatus: {
          ...state.phoneVerificationStatus,
          ...action.status,
          isLoading: {
            ...state.phoneVerificationStatus.isLoading,
            ...action.status.isLoading
          }
        }
      };

    case SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS:
      return {
        ...state,
        phoneVerificationStatus: {
          ...state.phoneVerificationStatus,
          ...action.status,
          isLoading: {
            ...state.phoneVerificationStatus.isLoading,
            ...action.status.isLoading
          }
        }
      };

    case SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS:
      return {
        ...state,
        emailVerificationStatus: {
          ...state.emailVerificationStatus,
          ...action.status,
          isLoading: {
            ...state.emailVerificationStatus.isLoading,
            ...action.status.isLoading
          }
        }
      };

    case SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS:
      return {
        ...state,
        totpVerificationStatus: {
          ...state.totpVerificationStatus,
          ...action.status,
          isLoading: {
            ...action.status.isLoading
          }
        }
      };

    case TIMER_START:
      return {
        ...state,
        timer: {
          timerRef: action.ref,
          countdown: 30
        }
      };

    case TIMER_TICK:
      return {
        ...state,
        timer: {
          ...state.timer,
          countdown: state.timer.countdown - 1
        }
      };

    case TIMER_STOP:
      return {
        ...state,
        timer: {
          countdown: 0,
          timerRef: null
        }
      };

    default:
      return state;
  }
}
