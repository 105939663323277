import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./_setTotpFactorFlow.scss";
import SubmitButton from "@cx/ui/SubmitButton";
import { reportElementClickedToGTM } from "../../api/gtm-analytics";

const TotpAcquireAppPage = (props) => {
  const onCancel = (event) => {
    reportElementClickedToGTM(
      "cancel-totp-flow-button",
      "Authentication App Flow Canceled",
      "Authentication App Acquire App Page"
    );
    props.onCancel(false);
  };

  const t = props.t;

  return (
    <div>
      <h2>{t("security-tab.totp-configure-acquire-app-title")}</h2>
      <p>{t("security-tab.totp-configure-acquire-app-text-1")}</p>
      <br />
      <p>{t("security-tab.totp-configure-acquire-app-text-2")}</p>
      <div style={{ paddingTop: "10px" }}>
        <a
          href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200"
          style={{ width: "120px", height: "40px" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt={t(
              "security-tab.totp-configure-usage-google-authenticator-app-store-logo-alt-text"
            )}
            src={t(
              "security-tab.totp-configure-usage-google-authenticator-app-store-logo-link"
            )}
            style={{ width: "120px", height: "40px" }}
          />
        </a>

        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          style={{ width: "120px", height: "40px" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt={t(
              "security-tab.totp-configure-usage-google-authenticator-play-store-logo-alt-text"
            )}
            src={t(
              "security-tab.totp-configure-usage-google-authenticator-play-store-logo-link"
            )}
            style={{ width: "140px", height: "60px" }}
          />
        </a>
      </div>

      <div id="totp-factor-page-buttons-group">
        <SubmitButton
          htmlId="cancel-totp-flow-button"
          className="recovery-page-button"
          buttonStyle="link"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </SubmitButton>
        <SubmitButton
          htmlId="send-verification-code"
          className="recovery-page-button"
          buttonStyle="primary"
          onClick={props.setTotpFactor}
        >
          {t("common.continue")}
        </SubmitButton>
      </div>
    </div>
  );
};

TotpAcquireAppPage.propTypes = {
  errorMessage: PropTypes.object,
  flowStatus: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  setTotpFactor: PropTypes.func.isRequired,
  t: PropTypes.func,
  user: PropTypes.object.isRequired,
  userTotpFactorChanging: PropTypes.func.isRequired
};

export default withTranslation()(TotpAcquireAppPage);
