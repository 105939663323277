import {
  ADD_SOLUTION_LINK,
  CHANGE_PREFERRED_USER_NAME_SUCCESS,
  CHANGE_USER_PRIMARY_EMAIL_SUCCESS,
  CLEAR_PREFERRED_USER_NAME_AVAILABILITY,
  CLEAR_USER_PRIMARY_EMAIL_SUCCESS,
  LOAD_USER_SUCCESS,
  PREFERRED_USER_NAME_AVAILABLE,
  PREFERRED_USER_NAME_UNAVAILABLE
} from "./actionTypes";
import {
  checkUsernameAvailability,
  deletePrimaryEmail,
  getUserETag,
  postPreferredUsername,
  postPrimaryEmail,
  postSolutionLink
} from "../api/userApi";
import {
  displayAlertMessage,
  displayToastMessage,
  ERROR_MESSAGES,
  hideAlertMessage,
  updateUsernameFailed
} from "./statusActions";
import { solutionLinkAddedString } from "../utils/stringConstants";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../utils/MessageTypes";
import { reportToGTM } from "../api/gtm-analytics";
import { attachSolutionSuccess } from "./solutionActions";
import i18n from "../i18n.js";

function loadUserSuccess(user) {
  return { type: LOAD_USER_SUCCESS, user };
}

export function loadUser(user) {
  return function (dispatch) {
    dispatch(loadUserSuccess(user));
    if (user.solutionLinks) {
      user.solutionLinks.forEach((solution) => {
        dispatch(attachSolutionSuccess({ solutionId: solution.solutionId }));
      });
    }
  };
}

export function updateUsername(username) {
  return { type: CHANGE_PREFERRED_USER_NAME_SUCCESS, username };
}

export function changeUsername(newPreferredUserName) {
  reportProfileChangeToGTM("Username", "Change Username Attempted");
  return async function (dispatch) {
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    getUserETag()
      .then((etag) => {
        postPreferredUsername(newPreferredUserName, etag)
          .then((resp) => {
            dispatch(updateUsername(newPreferredUserName));
            dispatch(clearUsernameAvailability());
            displaySuccessToast(
              dispatch,
              i18n.t(
                "messages.success.username-changed",
                "Username has been updated."
              )
            );
          })
          .catch((error) => {
            if (error.response.status === 409) {
              dispatch(
                updateUsernameFailed(
                  i18n.t(
                    "messages.errors.username-already-taken",
                    "Username already taken"
                  )
                )
              );
            } else if (error.response.status === 422) {
              dispatch(
                updateUsernameFailed(
                  i18n.t(
                    "messages.errors.username-cant-be-used",
                    "This username can't be used"
                  )
                )
              );
            } else {
              displayProfileErrorAlert(dispatch);
            }
            dispatch(clearUsernameAvailability());
          });
      })
      .catch((error) => {
        dispatch(clearUsernameAvailability());
        displayProfileErrorAlert(dispatch);
      });
  };
}

export function usernameAvailable() {
  return { type: PREFERRED_USER_NAME_AVAILABLE };
}

export function usernameUnavailable() {
  return { type: PREFERRED_USER_NAME_UNAVAILABLE };
}

export function clearUsernameAvailability() {
  return { type: CLEAR_PREFERRED_USER_NAME_AVAILABILITY };
}

export function cancelUsernameChange(oldUsername) {
  return async function (dispatch) {
    dispatch(clearUsernameAvailability());
    dispatch(updateUsername(""));
    dispatch(updateUsername(oldUsername));
  };
}

export function verifyUsernameAvailability(username) {
  return async function (dispatch) {
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    const trimmedUsername = username.trim();
    if (
      !trimmedUsername.match(/^([a-zA-Z0-9!#$%&'*+\-/=?^_`.{|}~@]+)$/) ||
      trimmedUsername.length < 5 ||
      trimmedUsername.length > 100
    ) {
      dispatch(
        updateUsernameFailed(
          i18n.t("messages.errors.username-invalid", "Username is invalid")
        )
      );
      return Promise.reject(); // not necessary but done to maintain consistency
    }
    return checkUsernameAvailability(trimmedUsername)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(usernameAvailable());
        }
      })
      .catch((error) => {
        const errorStatus = error.response ? error.response.status : -1;
        switch (errorStatus) {
          case 409:
            dispatch(
              updateUsernameFailed(
                i18n.t(
                  "messages.errors.username-already-taken",
                  "Username already taken"
                )
              )
            );
            break;
          case 422:
            dispatch(
              updateUsernameFailed(
                i18n.t(
                  "messages.errors.username-invalid",
                  "Username is invalid"
                )
              )
            );
            break;
          default:
            displayProfileErrorAlert(dispatch);
        }
      });
  };
}

export function changePrimaryEmailDispatch(primaryEmail) {
  return { type: CHANGE_USER_PRIMARY_EMAIL_SUCCESS, primaryEmail };
}

export function changePrimaryEmail(updatedEmail) {
  reportProfileChangeToGTM("ContactEmail", "Change Contact Email Attempted");
  return async function (dispatch) {
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    return postPrimaryEmail(updatedEmail)
      .then((resp) => {
        if (resp.status === 202) {
          dispatch(changePrimaryEmailDispatch(updatedEmail));
          displaySuccessToast(
            dispatch,
            i18n.t(
              "messages.success.contact-email-changed",
              "Contact email has been updated."
            )
          );
        } else if (resp.status === 422) {
          displayEmailUpdateErrorAlert(dispatch);
        }
        return true;
      })
      .catch((error) => {
        if (error.response.status === 422) {
          displayEmailUpdateErrorAlert(dispatch);
        } else {
          displayProfileErrorAlert(dispatch);
        }
        return false;
      });
  };
}

export function clearPrimaryEmailDispatch() {
  return { type: CLEAR_USER_PRIMARY_EMAIL_SUCCESS };
}

export function clearPrimaryEmail() {
  reportProfileChangeToGTM("ContactEmail", "Delete Contact Email Attempted");
  return async function (dispatch) {
    dispatch(hideAlertMessage(ERROR_MESSAGES.PROFILE_SERVICE_ERROR));
    return deletePrimaryEmail()
      .then((resp) => {
        if (resp.status === 202) {
          dispatch(clearPrimaryEmailDispatch());
          displaySuccessToast(
            dispatch,
            i18n.t(
              "messages.success.contact-email-cleared",
              "Contact email has been deleted."
            )
          );
          return true;
        } else if (resp.status === 422) {
          displayEmailUpdateErrorAlert(dispatch);
          return false;
        }
      })
      .catch((error) => {
        if (error.response.status === 422) {
          displayEmailUpdateErrorAlert(dispatch);
        } else {
          displayProfileErrorAlert(dispatch);
        }
        return false;
      });
  };
}

export function cancelPrimaryEmailChange(oldContactEmail) {
  return async function (dispatch) {
    dispatch(changePrimaryEmailDispatch(""));
    dispatch(changePrimaryEmailDispatch(oldContactEmail));
  };
}

function reportProfileChangeToGTM(eventElement, eventResult) {
  reportToGTM(eventElement, "Blurred", eventResult, "Profile");
}

export function addSolutionLink(customer) {
  return { type: ADD_SOLUTION_LINK, customer };
}

export function linkSolution(solution) {
  return async function (dispatch) {
    const { data } = await postSolutionLink(solution);
    dispatch(addSolutionLink(data));
    dispatch(displayToastMessage(solutionLinkAddedString));
  };
}

function displayEmailUpdateErrorAlert(dispatch) {
  dispatch(
    displayAlertMessage({
      payload: ERROR_MESSAGES.INVALID_EMAIL_ERROR,
      type: MESSAGE_TYPE.ALERT,
      severity: MESSAGE_SEVERITY.FAIL
    })
  );
}

function displayProfileErrorAlert(dispatch) {
  dispatch(
    displayAlertMessage({
      payload: ERROR_MESSAGES.PROFILE_SERVICE_ERROR,
      type: MESSAGE_TYPE.ALERT,
      severity: MESSAGE_SEVERITY.FAIL
    })
  );
}

function displaySuccessToast(dispatch, message) {
  dispatch(
    displayToastMessage({
      text: message,
      type: MESSAGE_TYPE.TOAST,
      severity: MESSAGE_SEVERITY.SUCCESS
    })
  );
}
