import React from "react";
import { WebComponentLoader } from "@common-core/react-composable/web-components";
import appConfig from "../../config/appConfig";
import LoadingIndicatorCentered from "../reusableComponents/LoadingIndicatorCentered";
import Alert from "@cx/ui/Alert";
import { Trans } from "react-i18next";

const NotificationSettings = () => {
  return (
    <WebComponentLoader
      {...appConfig.notificationSettingsComponent}
      placeholder={() => <></>}
      loading={() => (
        <LoadingIndicatorCentered
          htmlId="notification-settings-loading"
          size="large"
          position="relative"
        />
      )}
      failure={() => {
        return (
          <>
            <div>
              <br />
              <Alert htmlId="load-failed-message" type="danger">
                <h5>
                  <Trans i18nKey="messages.errors.user-service-error" />
                </h5>
              </Alert>
            </div>
          </>
        );
      }}
    />
  );
};

export default NotificationSettings;
