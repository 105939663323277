import {
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS,
  ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS,
  ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING,
  LOAD_AUTH_FACTORS_SUCCESS
} from "../actions/actionTypes";

const initialState = {
  authFactors: {
    flowStatus: {},
    email: {
      emailAddress: "",
      verified: false,
      errorMessage: null
    },
    sms: {
      phoneNumber: "",
      verified: false,
      errorMessage: null
    },
    totp: {
      verified: false,
      errorMessage: null
    }
  }
};
export default function userFactorsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_AUTH_FACTORS_SUCCESS: {
      const authFactors = action.authFactors;
      return {
        ...state,
        authFactors
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: {},
          email: {
            emailAddress: "",
            verified: false,
            errorMessage: null
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          email: {
            ...state.authFactors.email,
            emailAddress: action.emailAddress,
            verified: action.verified
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          email: {
            ...state.authFactors.email,
            errorMessage: action.errorMessage
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: action.flowStatus
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: {},
          sms: {
            phoneNumber: "",
            verified: false,
            errorMessage: null
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          sms: {
            ...state.authFactors.sms,
            phoneNumber: action.phoneNumber,
            verified: action.verified,
            errorMessage: null
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          sms: {
            ...state.authFactors.sms,
            errorMessage: action.errorMessage
          }
        }
      };
    }

    case ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: action.flowStatus
        }
      };
    }

    case ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: {},
          totp: {
            verified: false,
            errorMessage: null
          }
        }
      };
    }

    case ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          totp: {
            ...state.authFactors.totp,
            verified: action.verified,
            errorMessage: null
          }
        }
      };
    }

    case ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING: {
      return {
        ...state,
        authFactors: {
          ...state.authFactors,
          flowStatus: action.flowStatus
        }
      };
    }

    default: {
      return state;
    }
  }
}
