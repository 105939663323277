// This file centralizes HTTP requests using axios to keep calls short and consistent.
import appConfig from "../config/appConfig";
import axios from "axios";
import AuthHelper from "../components/auth/AuthLib";
import { USER_FACTOR_TYPE } from "../utils/userFactorTypes";

const MEDIA_TYPE = "application/vnd.coxauto.v1+json";
const USER_FACTORS_MINE = "/user-factors/mine";
const USER_FACTORS = "/user-factors";

const UserFactorsApiAxiosInstance = (function () {
  let instance;

  function createInstance() {
    const config = {
      headers: {
        Accept: MEDIA_TYPE
      },
      withCredentials: false
    };
    const mergedConfig = Object.assign({}, appConfig.userFactorsApi, config);
    return axios.create(mergedConfig);
  }

  return {
    getInstance: () => {
      if (!instance) {
        instance = createInstance();
      }
      return instance;
    }
  };
})();

async function getAccessToken() {
  return await AuthHelper.getToken();
}

async function refreshAuthHeader() {
  const token = await getAccessToken();
  UserFactorsApiAxiosInstance.getInstance().defaults.headers["Authorization"] =
    "Bearer " + token;
}

/**
 *
 * Examples of returned promise:
 *example 1: {
    count: 2,
    items: [
      {
        userId: "dummy-user-id",
        type: "SMS",
        phone: "+12223334444"
      },
      {
        userId: "dummy-user-id",
        type: "EMAIL",
        email: "user@example.com"
      }
    ]
  }
 *
 * example 2: {
    count: 1,
    items: [
      {
        userId: "dummy-user-id",
        type: "EMAIL",
        email: "user@example.com"
      }
    ]
  }
 *
 */
export async function getAllUserFactors() {
  await refreshAuthHeader();
  return UserFactorsApiAxiosInstance.getInstance().get(USER_FACTORS_MINE);
}

/**
 *
 * Examples of returned JSON objects:
 *example 1: {
    data: {
      emailAddress: "someone@somewhere.com",
      isVerified: false
    }
  }
 *
 example 2: {
    data: {
      phoneNumber: "5122222222",
      isVerified: false
    }
  }
 */

export async function postUserFactor(factorType, value = null) {
  const data = {
    type: factorType
  };
  if (factorType === USER_FACTOR_TYPE.SMS) {
    data.phoneNumber = value;
  } else if (factorType === USER_FACTOR_TYPE.EMAIL) {
    data.emailAddress = value;
  }

  await refreshAuthHeader();
  return UserFactorsApiAxiosInstance.getInstance().post(USER_FACTORS, data);
}

/**
 *
 * @param factorType
 * @param verificationCode
 * @returns {Promise<AxiosResponse<any>>}
 *
 *
 * Examples of returned promises:
 *example 1: {
    data: {
      phoneNumber: "5125555555",
      isVerified: true
    }
  };
 * example 2: {
    data: {
      emailAddress: "someone@somewhere.com",
      isVerified: true
    }
  };
 *
 * example 3: Promise.reject({
    response: { status: 422 }
  })
 *
 */
export async function postUserFactorVerificationCode(
  factorType,
  verificationCode
) {
  await refreshAuthHeader();
  return UserFactorsApiAxiosInstance.getInstance().post(
    USER_FACTORS_MINE + "/type/" + factorType + "/verify",
    { verificationCode }
  );
}

/**
 *
 * @param factorType
 * @returns {Promise<AxiosResponse<any>>}
 *
 * Example of returned promise:
  example: Promise.reject({
   response: { status: 204 }
  })
 */
export async function deleteUserFactor(factorType) {
  await refreshAuthHeader();
  return UserFactorsApiAxiosInstance.getInstance().delete(
    USER_FACTORS_MINE + "/type/" + factorType,
    {}
  );
}
