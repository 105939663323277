import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ToastContainer, { toast } from "@cx/ui/Toast";
import "./_toastMessage.scss";
import { MESSAGE_TYPE, MESSAGE_SEVERITY } from "../../utils/MessageTypes";

function ToastMessage(props) {
  if (
    props.toastMessage !== undefined &&
    props.toastMessage.type === MESSAGE_TYPE.TOAST &&
    props.toastMessage.severity !== MESSAGE_SEVERITY.NONE // never render a NONE message
  ) {
    switch (props.toastMessage.severity) {
      case MESSAGE_SEVERITY.SUCCESS: {
        toast.success(props.toastMessage.text, {
          onClose: (props) => {},
          hideProgressBar: true
        });
        break;
      }
      case MESSAGE_SEVERITY.WARN: {
        toast.warning(props.toastMessage.text, {
          onClose: (props) => {},
          hideProgressBar: true
        });
        break;
      }
      case MESSAGE_SEVERITY.FAIL: {
        toast.error(props.toastMessage.text, {
          onClose: (props) => {},
          hideProgressBar: true
        });
        break;
      }
      case MESSAGE_SEVERITY.INFO:
      default:
        toast.info(props.toastMessage.text, {
          onClose: (props) => {},
          hideProgressBar: true
        });
    }
  }
  return <ToastContainer />;
}

ToastMessage.propTypes = {
  toastMessage: PropTypes.object
};

function mapStateToProps(state) {
  return {
    toastMessage: state.statusReducer.toastMessage
  };
}

export default connect(mapStateToProps, undefined)(ToastMessage);
