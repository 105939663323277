import jwtDecode from "jwt-decode";
import { isProdPartition, getEnv } from "../config/appConfig";
import AuthHelper from "../components/auth/AuthLib";

function renderBridgeBar(detectedLanguage) {
  let env = isProdPartition() ? "prod" : "qa";

  if (["dangerzone", "local"].includes(getEnv())) {
    env = isProdPartition() ? "dz_prod" : "dz_np";
  }

  const bbEl = window.document.querySelector("bridge-bar");
  AuthHelper.getToken().then((token) => {
    const decoded = jwtDecode(token);
    bbEl.bb.render({
      sessionId: decoded.jti,
      solutionGroupCode: "CRS",
      env,
      language: detectedLanguage.slice(0, 2)
    });
  });
}

export default {
  renderBridgeBar
};
