import React from "react";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import "./solutionCard.scss";
import SubmitButton from "@cx/ui/SubmitButton";
import appConfig from "../../config/appConfig";
import { reportToGTM } from "../../api/gtm-analytics";
import IconInfoOutline from "@cx/ui/Icons/IconInfoOutline";
import Tooltip from "@cx/ui/Tooltip";
import { Trans } from "react-i18next";
import { t } from "i18next";

const SolutionCard = ({
  solution,
  attached,
  isAttachingSolutions,
  comingSoon
}) => {
  const desiredState = btoa(
    '{ "authenticatedSolution": ' + JSON.stringify(solution) + " }"
  );

  const subdomain =
    solution.signinSubdomain === undefined ? "" : solution.signinSubdomain;
  const signinUrl = appConfig.signin.solutionLoginURL.replace(
    "{subdomain}",
    subdomain
  );

  let redirectUrl =
    signinUrl +
    "&nonce=" +
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15) +
    "&state=" +
    desiredState +
    "&redirect_uri=" +
    encodeURIComponent(appConfig.signin.redirectURL) +
    "&abortUrl=" +
    encodeURIComponent(appConfig.signin.abortURL) +
    "&client_id=" +
    appConfig.signin.clientId +
    "&solutionid=" +
    (solution.solutionId || solution.pairedSolution);

  if (solution.solutionAppId) {
    redirectUrl += "&solutionapplicationid=" + solution.solutionAppId;
  }

  const handleReportToGTM = () => {
    reportToGTM(
      `${solution.name} Attach Button`,
      "Clicked",
      "Open SignIn Solution Login Page",
      "Attached Solutions"
    );
  };
  const cardCLassNames = () => {
    const classNames = ["solution-card"];
    if (attached) {
      classNames.push("solution-card-attached");
    } else if (comingSoon) {
      classNames.push("solution-card-coming-soon");
    }
    return classNames.join(" ");
  };

  return (
    <Card
      htmlId={"solution-card-" + solution.solutionId}
      className={cardCLassNames()}
    >
      <img
        className="solution-card-img"
        src={solution.logoUrl}
        alt={solution.name}
      />
      {!attached && !comingSoon && (
        <SubmitButton
          htmlId={"solution-attach-button-" + solution.solutionId}
          onClick={() => {
            handleReportToGTM();
            setTimeout(() => window.location.assign(redirectUrl), 100);
          }}
          buttonStyle="link"
          isLoading={isAttachingSolutions.includes(solution.solutionId)}
          loadingText={<Trans i18nKey="common.attaching" />}
        >
          {<Trans i18nKey="common.attach" />}
        </SubmitButton>
      )}
      {comingSoon && (
        <div className={"coming-soon"}>
          <Tooltip
            htmlId="tooltipElement"
            tooltipContent={t("common.coming-soon-tooltip")}
            position="bottom"
          >
            <div>
              {<Trans i18nKey="common.coming-soon" />}
              {
                <IconInfoOutline
                  className={"icon-info"}
                  htmlId={"IconInfoOutline"}
                />
              }
            </div>
          </Tooltip>
        </div>
      )}
      {attached && <div className="checkmark" />}
      {solution.logoText !== undefined && solution.logoText !== "" ? (
        <p id="solution-card-text">{solution.logoText}</p>
      ) : (
        <div />
      )}
      {solution.regionRestrictions ? (
        <div className="solution-card-region">
          <span
            className={`flag-icon region-restrictions-${solution.regionRestrictions}`}
          />{" "}
          <Trans
            i18nKey={`region-restrictions.${solution.regionRestrictions}`}
          />
        </div>
      ) : null}
    </Card>
  );
};

SolutionCard.propTypes = {
  attached: PropTypes.bool.isRequired,
  comingSoon: PropTypes.bool,
  isAttachingSolutions: PropTypes.array,
  solution: PropTypes.shape({
    name: PropTypes.string.isRequired,
    solutionId: PropTypes.string.isRequired,
    solutionAppId: PropTypes.string,
    logoText: PropTypes.string,
    logoUrl: PropTypes.string,
    pairedSolution: PropTypes.string,
    signinSubdomain: PropTypes.string,
    regionRestrictions: PropTypes.string
  }).isRequired
};

export default SolutionCard;
