import TagManager from "react-gtm-module";
import * as stringConstants from "../utils/stringConstants";

export function reportElementClickedToGTM(element, result, location) {
  reportToGTM(element, "Clicked", result, location);
}

export function initializeDataLayer(env, user) {
  const { id, identity } = user.data;
  window.dataLayer.push({
    common: {
      dataLayerVersion: 2,
      user: {
        bridgePlatformId: id,
        bridgeUsername: identity.username,
        isInternalUser: false
      },
      application: {
        name: stringConstants.appDisplayName,
        version: stringConstants.appVersion,
        environment: env,
        isProduction: env === stringConstants.liveEnv
      }
    }
  });
  window.dataLayer.push({ event: "dataLayerReady", dataLayerReady: true });
}

/*
  Defined by https://coxautoinc.sharepoint.com/sites/department-user-experience/SitePages/User%20Event%20Tracking%20with%20Google%20Tag%20Manager.aspx
  Location - A breadcrumb trail of nested DOM components. These values should be consistent across events such that all
             user interactions within a given component have the same breadcrumb trail. The top-most item should break down
             into a reasonable number of application features.
  Element - The name of an element the user interacted with, from their perspective. This should be provided in readable
            English, i.e. passing in "Tab" as opposed to "li" or other DOM/code type identifiers (including classes and IDs).
  
  Action - The action that triggered the data collection (clicked, hovered, dragged, etc). This should be in readable
           English and past tense.
  Result - What occurred from the user's perspective (opened the date selector, etc). This should be in readable English
           and past tense for consistency across applications. Ideally, the value here would be the actual result of the
           user interaction, not simply a guess. For example, if a user kicked off an ajax process, if that process fails,
           the result should articulate that.
  
  Value - Any quantitative value associated with the action (a count of returned search results, etc).
   */
export function reportToGTM(element, action, result, location) {
  TagManager.dataLayer({
    dataLayer: {
      event: "userEvent",
      eventElement: element,
      eventAction: action,
      eventResult: result,
      eventLocation: location,
      eventValue: undefined
    }
  });
}
