export default function getParameterByName(name, url) {
  const sanitizedName = name.replace(/[[]]/g, "\\$&");
  const regex = new RegExp("[?&]" + sanitizedName + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
