import React, { useState } from "react";
import InputField from "../common/InputField";
import SubmitButton from "@cx/ui/SubmitButton";
import ModalDialog from "@cx/ui/ModalDialog";

import { Trans } from "react-i18next";
import PropTypes from "prop-types";

import "./_primaryEmailInputField.scss";

import { connect } from "react-redux";
import {
  cancelPrimaryEmailChange,
  changePrimaryEmail,
  clearPrimaryEmail
} from "../../actions/userActions";
import { isEmailValid } from "../../utils/validationUtil";

const CHANGE = "change";
const CLEAR = "clear";

const PrimaryEmailInputField = ({
  primaryEmail,
  changePrimaryEmail,
  cancelPrimaryEmailChange,
  clearPrimaryEmail
}) => {
  const [currentEmail, setCurrentEmail] = useState(primaryEmail);
  const [updatedEmail, setUpdatedEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [resetToInitialValue, setResetToInitialValue] = useState(false);
  const [modalValue, setModalValue] = useState(CHANGE);

  const validateEmail = (inputtedEmail) => {
    if (inputtedEmail === currentEmail) {
      setErrorMessage(null);
      return;
    }
    setUpdatedEmail(inputtedEmail ?? "");
    setErrorMessage(null);
    if (!inputtedEmail && currentEmail != null) {
      setErrorMessage(null);
      setResetToInitialValue(false);
      setModalValue(CLEAR);
      setShowModal(true);
    } else if (inputtedEmail) {
      setModalValue(CHANGE);
      if (isEmailValid(inputtedEmail)) {
        setErrorMessage(null);
        setResetToInitialValue(false);
        setShowModal(true);
      } else {
        setErrorMessage(<Trans i18nKey="messages.errors.email-is-invalid" />);
      }
    }
  };

  const cancel = () => {
    setShowModal(false);
    cancelPrimaryEmailChange(currentEmail);
    setResetToInitialValue(true);
  };

  const confirmChangePrimaryEmail = async () => {
    const response =
      modalValue === CHANGE
        ? await changePrimaryEmail(updatedEmail)
        : await clearPrimaryEmail();
    if (response) {
      setCurrentEmail(updatedEmail);
      setResetToInitialValue(false);
    } else {
      setResetToInitialValue(true);
    }
    setShowModal(false);
  };

  const confirmValues = {
    change: {
      confirmButton: "profile-tab.change-contact-email-confirm",
      confirmText: "profile-tab.change-contact-email-confirmation-text",
      modalTitle: "profile-tab.change-contact-email"
    },
    clear: {
      confirmButton: "profile-tab.clear-contact-email-confirm",
      confirmText: "profile-tab.clear-contact-email-confirmation-text",
      modalTitle: "profile-tab.clear-contact-email"
    }
  };

  return (
    <div>
      <InputField
        htmlId={"primary-email-input-field"}
        label={<Trans i18nKey="common.contact-email" />}
        initialValue={currentEmail}
        maxLength={100}
        errorMsg={errorMessage}
        submitAction={validateEmail}
        savingMessage={<Trans i18nKey="common.saving" />}
        required={false}
        resetValueToInitial={resetToInitialValue}
      />
      <ModalDialog
        htmlId="primary-email-confirmation-dialog"
        show={showModal}
        header={
          <ModalDialog.Title>
            <Trans i18nKey={confirmValues[modalValue].modalTitle} />
          </ModalDialog.Title>
        }
        onHide={cancel}
      >
        <div id="dialog-content">
          <p>
            <Trans i18nKey={confirmValues[modalValue].confirmText} />
          </p>
          <div id="dialog-button-group">
            <SubmitButton
              htmlId="confirm-change-primary-email"
              className="dialog-button"
              buttonStyle="primary"
              onClick={confirmChangePrimaryEmail}
              loadingText={
                <Trans i18nKey="profile-tab.changing-contact-email" />
              }
            >
              <Trans i18nKey={confirmValues[modalValue].confirmButton} />
            </SubmitButton>
            <SubmitButton
              htmlId="cancel-change-primary-email"
              className="dialog-button"
              buttonStyle="link"
              onClick={cancel}
            >
              <Trans i18nKey="profile-tab.change-contact-email-cancel" />
            </SubmitButton>
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

PrimaryEmailInputField.propTypes = {
  cancelPrimaryEmailChange: PropTypes.func,
  changePrimaryEmail: PropTypes.func,
  clearPrimaryEmail: PropTypes.func,
  primaryEmail: PropTypes.string
};

const mapStateToProps = (state) => ({
  user: state.userReducer.user
});

const mapDispatchToProps = {
  changePrimaryEmail,
  cancelPrimaryEmailChange,
  clearPrimaryEmail
};

const connectedPrimaryEmailInputField = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrimaryEmailInputField);

export default connectedPrimaryEmailInputField;
