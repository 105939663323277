// Status
export const ADD_ALERT_MESSAGE = "ADD_ALERT_MESSAGE";
export const HIDE_ALERT_MESSAGE = "HIDE_ALERT_MESSAGE";
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const CHANGE_EMAIL_FIELD_SUCCESS_STATUS =
  "CHANGE_EMAIL_FIELD_SUCCESS_STATUS";
export const CHANGE_EMAIL_FIELD_FAIL_STATUS = "CHANGE_EMAIL_FIELD_FAIL_STATUS";
export const CHANGE_EMAIL_FIELD_PROGRESS_STATUS =
  "CHANGE_EMAIL_FIELD_PROGRESS_STATUS";
export const CHANGE_EMAIL_FIELD_CLEAR_STATUS =
  "CHANGE_EMAIL_FIELD_CLEAR_STATUS";

export const CHANGE_USERNAME_FIELD_FAIL_STATUS =
  "CHANGE_USERNAME_FIELD_FAIL_STATUS";
export const CLEAR_USERNAME_FIELD_STATUS = "CLEAR_USERNAME_FIELD_STATUS";
export const CHANGE_PHONE_FIELD_STATUS = "CHANGE_PHONE_FIELD_STATUS";
export const TOGGLE_USER_PROFILE_RECOVERY_PHONE_VERIFICATION_STATUS =
  "TOGGLE_USER_PROFILE_RECOVERY_PHONE_VERIFICATION_STATUS";

export const CHANGE_EMAIL_FIELD_STATUS = "CHANGE_EMAIL_FIELD_STATUS";
export const SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS =
  "SET_ACCOUNT_SECURITY_RECOVERY_EMAIL_VERIFICATION_STATUS";
export const SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS =
  "SET_ACCOUNT_SECURITY_RECOVERY_PHONE_VERIFICATION_STATUS";

export const CHANGE_TOTP_FACTOR_FIELD_STATUS =
  "CHANGE_TOTP_FACTOR_FIELD_STATUS";
export const SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS =
  "SET_ACCOUNT_SECURITY_TOTP_FACTOR_VERIFICATION_STATUS";

// User
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const CHANGE_PREFERRED_USER_NAME_SUCCESS =
  "CHANGE_PREFERRED_USER_NAME_SUCCESS";
export const PREFERRED_USER_NAME_AVAILABLE =
  "PREFERRED_USER_NAME_AVAILABLE_SUCCESS";
export const PREFERRED_USER_NAME_UNAVAILABLE =
  "PREFERRED_USER_NAME_UNAVAILABLE";
export const CLEAR_PREFERRED_USER_NAME_AVAILABILITY =
  "CLEAR_PREFERRED_USER_NAME_AVAILABILITY";
export const CHANGE_USER_PRIMARY_EMAIL_SUCCESS =
  "CHANGE_USER_PRIMARY_EMAIL_SUCCESS";
export const CHANGE_USER_PRIMARY_EMAIL_FAILURE =
  "CHANGE_USER_PRIMARY_EMAIL_FAILURE";
export const CLEAR_USER_PRIMARY_EMAIL_SUCCESS =
  "CLEAR_USER_PRIMARY_EMAIL_SUCCESS";
export const CLEAR_USER_PRIMARY_EMAIL_FAILURE =
  "CLEAR_USER_PRIMARY_EMAIL_FAILURE";

export const ADD_SOLUTION_LINK = "ADD_SOLUTION_LINK";

// Account security
export const LOAD_AUTH_FACTORS_SUCCESS = "LOAD_AUTH_FACTORS_SUCCESS";
export const ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS =
  "ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_SUCCESS";
export const ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED =
  "ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_CANCELED";
export const ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING =
  "ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGING";
export const ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS =
  "ACCOUNT_SECURITY_RECOVERY_EMAIL_CHANGE_STATUS";
export const ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS =
  "ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_SUCCESS";
export const ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS =
  "ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_STATUS";
export const ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED =
  "ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGE_CANCELED";
export const ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING =
  "ACCOUNT_SECURITY_RECOVERY_PHONE_CHANGING";
export const ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS =
  "ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_SUCCESS";
export const ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED =
  "ACCOUNT_SECURITY_TOTP_FACTOR_CHANGE_CANCELED";
export const ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING =
  "ACCOUNT_SECURITY_TOTP_FACTOR_CHANGING";

// Solutions
export const ATTACH_SOLUTION = "ATTACH_SOLUTION";
export const ATTACHING_SOLUTION_INDICATOR = "ATTACHING_SOLUTION_INDICATOR";

// Auth
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_WILL_EXPIRE = "AUTH_WILL_EXPIRE";
export const AUTH_EXPIRED = "AUTH_EXPIRED";
export const SET_AUTH_CLIENT = "SET_AUTH_CLIENT";

// Timer
export const TIMER_START = "TIMER_START";
export const TIMER_TICK = "TIMER_TICK";
export const TIMER_STOP = "TIMER_STOP";
