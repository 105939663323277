import React from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";

import "./_delayedMessage.scss";

const defaultDelay = 500; // Delay in milliseconds

export default class DelayedMessage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };

    this.enableMessage = this.enableMessage.bind(this);
    this.timer = setTimeout(this.enableMessage, props.delay || defaultDelay);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  enableMessage() {
    this.setState({ visible: true });
  }

  render() {
    const { message } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return <React.Fragment />;
    }

    return (
      <div className="message">
        <LoadingIndicator
          htmlId="LoadingIndicatorSmall"
          size="small"
          floatToSide="left"
        />
        &nbsp;{message}
      </div>
    );
  }
}

DelayedMessage.propTypes = {
  delay: PropTypes.number,
  message: PropTypes.node
};
