export const MESSAGE_TYPE = {
  ALERT: "Alert",
  TOAST: "Toast",
  FIELD: "Field"
};

export const MESSAGE_SEVERITY = {
  SUCCESS: "Success",
  INFO: "Info",
  WARN: "Warn",
  FAIL: "Fail",
  NONE: "None" // NONE means there is no message.
};
