import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./_app.scss";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { getUser } from "../api/userApi";
import { initializeDataLayer } from "../api/gtm-analytics";
import { getEnv, getPartition } from "../config/appConfig";
import { connect } from "react-redux";
import { loadUser } from "../actions/userActions";
import HomePage from "./pages/HomePage";
import Alert from "@cx/ui/Alert";
import { Trans } from "react-i18next";
import Button from "@cx/ui/Button";
import LoadingIndicatorCentered from "./reusableComponents/LoadingIndicatorCentered";

const App = (props) => {
  const [attemptCount, setAttemptCount] = useState(0);
  const [userFound, setUserFound] = useState(false);
  const [userLoadFailed, setUserLoadFailed] = useState(false);

  useEffect(() => {
    getUser()
      .then(async (user) => {
        // initialize gtm data layer
        initializeDataLayer(getEnv(), user);

        props.loadUser(user.data);
        const ffUser = {
          key: user.data.id,
          custom: {
            id: user.data.identity.username,
            partition: getPartition(),
            solutionLinks: user.data.solutionLinks
              ? user.data.solutionLinks
                  .map((lnk) => lnk.solutionId.toLowerCase())
                  .join(",")
              : "NOT_LINKED"
          }
        };
        await props.ldClient.identify(ffUser);
        setUserFound(true);
      })
      .catch((reason) => {
        // eslint-disable-next-line no-console
        console.error("Failed to get user: " + reason);
        setUserLoadFailed(true);
      });
  }, [props, attemptCount]);

  if (userFound) {
    return (
      <div className="main-layout">
        <div id="wrapper" className="content">
          <HomePage />
        </div>
      </div>
    );
  }

  if (userLoadFailed) {
    return (
      <div>
        <br />
        <Alert htmlId="load-failed-message" type="danger">
          <h5>
            <Trans i18nKey="messages.errors.user-service-error" />
          </h5>
        </Alert>
        <Button
          buttonStyle="secondary"
          htmlId="try-again-button"
          onClick={() => setAttemptCount(attemptCount + 1)}
        >
          <Trans i18nKey="common.try-again" />
        </Button>
      </div>
    );
  }

  return (
    <div>
      <LoadingIndicatorCentered htmlId="ff-load-pending" size="large" />
    </div>
  );
};

App.propTypes = {
  ldClient: PropTypes.shape({
    identify: PropTypes.func.isRequired
  }).isRequired,
  loadUser: PropTypes.func.isRequired
};

const connectedApp = connect(null, { loadUser })(App);
export default withLDConsumer({})(connectedApp);
