export const FLOW_STATE = {
  SHOW_REMOVE_EMAIL_FLOW: "SHOW_REMOVE_EMAIL_FLOW",
  HIDE_REMOVE_EMAIL_FLOW: "HIDE_REMOVE_EMAIL_FLOW",
  SHOW_SET_EMAIL_FLOW: "SHOW_SET_EMAIL_FLOW",
  HIDE_SET_EMAIL_FLOW: "HIDE_SET_EMAIL_FLOW",
  SHOW_REMOVE_PHONE_NUMBER_FLOW: "SHOW_REMOVE_PHONE_NUMBER_FLOW",
  HIDE_REMOVE_PHONE_NUMBER_FLOW: "HIDE_REMOVE_PHONE_NUMBER_FLOW",
  SHOW_SET_PHONE_NUMBER_FLOW: "SHOW_SET_PHONE_NUMBER_FLOW",
  HIDE_SET_PHONE_NUMBER_FLOW: "HIDE_SET_PHONE_NUMBER_FLOW",
  SHOW_REMOVE_TOTP_FLOW: "SHOW_REMOVE_TOTP_FLOW",
  HIDE_REMOVE_TOTP_FLOW: "HIDE_REMOVE_TOTP_FLOW",
  SHOW_SET_TOTP_FLOW: "SHOW_SET_TOTP_FLOW",
  HIDE_SET_TOTP_FLOW: "HIDE_SET_TOTP_FLOW"
};
