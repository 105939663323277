import React from "react";
import PropTypes from "prop-types";
import AccountInformation from "./AccountInformation";
import ContactInformation from "../reusableComponents/ContactInformation";
import "./_profile.scss";
import { connect } from "react-redux";

export const Profile = ({ user }) => {
  const { givenName, familyName, primaryEmail } = user.profile;
  return (
    <div className="profile-wrapper">
      <>
        <AccountInformation />
        <ContactInformation
          givenName={givenName}
          familyName={familyName}
          primaryEmail={primaryEmail}
        />
      </>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object.isRequired
};

export { Profile as ProfileNoRedux };
function mapStateToProps(state) {
  return {
    user: state.userReducer.user
  };
}

const connectedProfile = connect(mapStateToProps)(Profile);
export default connectedProfile;
