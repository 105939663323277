import {
  ADD_ALERT_MESSAGE,
  CHANGE_EMAIL_FIELD_CLEAR_STATUS,
  CHANGE_EMAIL_FIELD_FAIL_STATUS,
  CHANGE_EMAIL_FIELD_PROGRESS_STATUS,
  CHANGE_EMAIL_FIELD_SUCCESS_STATUS,
  CHANGE_PHONE_FIELD_STATUS,
  CHANGE_EMAIL_FIELD_STATUS,
  CHANGE_USERNAME_FIELD_FAIL_STATUS,
  CLEAR_USERNAME_FIELD_STATUS,
  HIDE_ALERT_MESSAGE,
  SHOW_TOAST_MESSAGE
} from "./actionTypes";
import i18n from "../i18n.js";

export const ERROR_MESSAGES = {
  PROFILE_SERVICE_ERROR: {
    id: 1,
    text: i18n.t(
      "messages.errors.profile-service-error",
      "Could not update your profile. Our service may be temporarily unavailable. You may try again in a few minutes. If you continue to see this message, please contact support."
    )
  },
  SOLUTION_ATTACHMENT_SERVICE_ERROR: {
    id: 2,
    text: i18n.t(
      "messages.errors.solution-service-error",
      "Could not attach your solution. Our service may be temporarily unavailable. You may try again in a few minutes. If you continue to see this message, please contact support."
    )
  },
  SOLUTION_ATTACHMENT_ALREADY_ATTACHED: {
    id: 3,
    text: i18n.t(
      "messages.errors.solution-already-attached-error",
      "Could not attach your solution. The account you attempted to link to your Bridge ID account has already been attached by another user."
    )
  },
  AUTH_ERROR: {
    id: 4,
    text: i18n.t(
      "messages.errors.auth",
      "An authentication error has occurred."
    )
  },
  AUTH_TIMED_OUT: {
    id: 5,
    text: i18n.t(
      "messages.errors.auth-timed-out",
      "An authentication error has occurred."
    )
  },
  AUTH_FACTORS_SERVICE_ERROR: {
    id: 6,
    text: i18n.t(
      "messages.errors.user-factors-service-error",
      "Could not update your authentication factors. Our service may be temporarily unavailable. You may try again in a few minutes. If you continue to see this message, please contact support."
    )
  },
  AUTH_FACTORS_EMAIL_IN_USE_ERROR: {
    id: 7,
    text: i18n.t(
      "messages.errors.email-change-fail",
      "Email is already in use. Email is not required if you have a verified mobile phone."
    )
  },
  AUTH_FACTORS_PHONE_NUMBER_IN_USE_ERROR: {
    id: 8,
    text: i18n.t(
      "messages.errors.phone-number-change-fail",
      "Phone number is already in use. Please use another phone number."
    )
  },
  INVALID_EMAIL_ERROR: {
    id: 9,
    text: i18n.t("messages.errors.email-is-invalid", "Email is invalid")
  }
};

export function displayAlertMessage(message) {
  return { type: ADD_ALERT_MESSAGE, message };
}

export function hideAlertMessage(message) {
  return { type: HIDE_ALERT_MESSAGE, message };
}

export function displayToastMessage(message) {
  return { type: SHOW_TOAST_MESSAGE, message };
}

export function displayEmailChangeSucceededStatus(message) {
  return { type: CHANGE_EMAIL_FIELD_SUCCESS_STATUS, message };
}

export function displayEmailChangeFailedStatus(message) {
  return { type: CHANGE_EMAIL_FIELD_FAIL_STATUS, message };
}

export function displayEmailChangeProgressStatus(message) {
  return { type: CHANGE_EMAIL_FIELD_PROGRESS_STATUS, message };
}

export function clearEmailFieldStatus() {
  return { type: CHANGE_EMAIL_FIELD_CLEAR_STATUS };
}

export function updateFieldStatus(message) {
  return { type: CHANGE_PHONE_FIELD_STATUS, message };
}

export function updateEmailFieldStatus(message) {
  return { type: CHANGE_EMAIL_FIELD_STATUS, message };
}

export function updateUsernameFailed(message) {
  return { type: CHANGE_USERNAME_FIELD_FAIL_STATUS, message };
}

export function clearUsernameFieldStatus() {
  return { type: CLEAR_USERNAME_FIELD_STATUS };
}
