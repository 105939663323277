import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "@cx/ui/ModalDialog";
import "./_setTotpFactorFlow.scss";
import { withTranslation } from "react-i18next";
import TotpSecretPage from "./TotpSecretPage";
import EnterTotpVerificationCodePage from "./EnterTotpVerificationCodePage";
import TotpAcquireAppPage from "./TotpAcquireAppPage";
import StepIndicator from "@cx/ui/StepIndicator";

class SetTotpFactorFlow extends React.Component {
  onCancel = (event) => {
    this.props.onCancel(event);
  };

  render() {
    const { show } = this.props;

    return (
      <ModalDialog
        htmlId="set-totp-factor-flow"
        show={show}
        closeButton={true}
        displayCloseButton={false}
        className="set-totp-factor-flow"
      >
        <div id="totp-modal-content">
          <StepIndicator
            htmlId="totp-flow-step-indicator"
            labels={[
              this.props.t("security-tab.totp-step-indicator-step1"),
              this.props.t("security-tab.totp-step-indicator-step2"),
              this.props.t("security-tab.totp-step-indicator-step3")
            ]}
            activeStep={this.props.flowStatus.page}
          />
          {this.props.flowStatus.page === 1 && (
            <TotpAcquireAppPage
              onCancel={this.onCancel}
              errorMessage={this.props.errorMessage}
              flowStatus={this.props.flowStatus}
              setTotpFactor={this.props.setTotpFactor}
              user={this.props.user}
              userTotpFactorChanging={this.props.userTotpFactorChanging}
            />
          )}
          {this.props.flowStatus.page === 2 && (
            <TotpSecretPage
              onCancel={this.onCancel}
              errorMessage={this.props.errorMessage}
              flowStatus={this.props.flowStatus}
              setTotpAcquireApp={this.props.setTotpAcquireApp}
              submitUserTotpFactor={this.props.submitUserTotpFactor}
              user={this.props.user}
              userTotpFactorChanging={this.props.userTotpFactorChanging}
            />
          )}
          {this.props.flowStatus.page === 3 && (
            <EnterTotpVerificationCodePage
              onCancel={this.onCancel}
              setTotpFactor={this.props.setTotpFactor}
              totpVerificationStatus={this.props.totpVerificationStatus}
              setTotpVerificationStatus={this.props.setTotpVerificationStatus}
              verifyTotpFactor={this.props.verifyTotpFactor}
            />
          )}
        </div>
      </ModalDialog>
    );
  }
}

SetTotpFactorFlow.propTypes = {
  dispatch: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  flowStatus: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  setTotpAcquireApp: PropTypes.func.isRequired,
  setTotpFactor: PropTypes.func.isRequired,
  setTotpVerificationStatus: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  submitUserTotpFactor: PropTypes.func.isRequired,
  t: PropTypes.func,
  totpVerificationStatus: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userTotpFactorChanging: PropTypes.func.isRequired,
  verifyTotpFactor: PropTypes.func.isRequired
};

export default withTranslation()(SetTotpFactorFlow);
